enum ComboBoxOptions {
  WHATEVER = 'whatever',
  YES = 'yes',
  NO = 'no'
}

interface ComboBoxChange {
  key: string,
  value: string
}

interface ComboBoxProps {
  defaultValue?: string;
  label: string;
  name: string;
  onChange: (event: ComboBoxChange) => void;
  value?: string;
}

export {
  ComboBoxOptions
}

export type {
  ComboBoxChange,
  ComboBoxProps
}
