import { useState } from 'react';
import { toast } from 'react-toast';

import LayoutForm from '../Components/LayoutForm';

import { createProperty, updateProperty } from '../../../services/actions';
import { Method, Property } from '../../../static/types';
import { data, initialValues } from './form-data';
import { InputSize } from '../../../atoms/Input';

interface FormProperty {
  customCallback?: (event: any) => void;
  defaultValues?: any;
  method?: Method;
  size?: InputSize;
  title?: string;
}

const FormProperty = (props: FormProperty) => {
  const {
    defaultValues,
    method = Method.POST,
    size = InputSize.MEDIUM,
    title = "Adicionar propriedade",
    customCallback
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  
  const handleSubmit = async (values: Property) => {
    setLoading(true);

    // @ts-ignore
    let response;

    if (method === Method.POST) {
      response = createProperty(values);
    } else if (method === Method.PATCH) {
      response = updateProperty({ ...defaultValues, ...values });
    }

    if (!response) return;

    response
      .then(() => {
        // @ts-ignore
        customCallback && customCallback(response);

        if (method === Method.POST) {
          toast.success("Propriedade cadastrada com sucesso!");
          document.location.reload();
        } else {
          toast.success("Propriedade atualizada com sucesso!");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <LayoutForm
      data={data}
      handleSubmit={handleSubmit}
      initialValues={defaultValues ?? initialValues}
      loading={loading}
      method={method}
      title={title}
      size={size}
      submitText={method === Method.PATCH ? 'Atualizar' : 'Cadastrar'}
    />
  );
};

export default FormProperty;
