import { ReactElement } from 'react';
import { BadgeInfoProps } from './types';

import { IconList, Informations } from '../../static/types';
import InfoBadge from '../../atoms/InfoBadge';

import './index.css';
import { translate } from '../../utils/translate';
import { isSmallDesksize } from '../../utils/screen';

const BadgeInfoList = (props: BadgeInfoProps): ReactElement => {
  const { data = {}, short, showLabel, isPropertyList } = props;

  const buildInfoBadges = (): Array<Informations> => {
    const badgeInfoKeys = ['room', 'bathroom', 'suite', 'garage', 'footage', 'minSubway'];

    let existingKeys = badgeInfoKeys.filter(key => data[key]);

    existingKeys = existingKeys.slice(0, short ? 4 : isPropertyList && isSmallDesksize() ? 5 : existingKeys.length);

    return (existingKeys || []).map(key => ({
      iconIndex: key as IconList,
      value: data[key],
      ...(showLabel && {
        label: translate(key),
      })
    }));
  }

  const informations = buildInfoBadges();

  return (
    <div className='c-card-property__badges'>
      {informations.map((info: Informations, index) => (
        <div key={index} className='c-card-property__badge'>
          <InfoBadge icon={info.iconIndex} value={info.value} label={info.label} />
        </div>
      ))}
    </div>
  )
};

export default BadgeInfoList;
