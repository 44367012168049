import React, { ReactElement, useEffect } from 'react';
import { Link as MUIlink } from '@mui/material';

import { ScreenLockerProps } from './types';

import './index.css';

const ScreenLocker = (props: ScreenLockerProps): ReactElement => {
  const { children } = props;

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';

    return () => {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };
  }, []);

  return (
    <div className='c-screen-locker'>
      {children}
    </div>
  );
}

export default ScreenLocker;