import React, { createContext, useState } from "react";

import { Client } from '../static/types';

type Value = string | number | undefined | null | unknown;

interface IAuth {
  isAuthenticated: boolean;
  user?: Client;
}

type AuthContext = {
  state: IAuth;
  setState: React.Dispatch<React.SetStateAction<IAuth>>;
}

const DEFAULT_VALUES = {
  state: {
    isAuthenticated: Boolean(localStorage.getItem("auth"))
  },
  setState: (state: IAuth) => {},
}

const AuthContext = createContext(DEFAULT_VALUES);

const AuthContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(DEFAULT_VALUES.state);

  return (
    <AuthContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthContextProvider };
