import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import Href from '../../atoms/Href';

import { IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';

import Logo from '../../static/images/card-visit-only-logo.png';

import { isMobileScreen } from '../../utils/screen';

import './index.css';

const Footer = (): ReactElement => {
  const WhatsIcon = getIcon(IconList.WHATSAPP);
  const InstagramIcon = getIcon(IconList.INSTAGRAM);
  const EmailIcon = getIcon(IconList.EMAIL);

  const isMobile = isMobileScreen();

  const bodyMessage = `Olá! \n\nEstou entrando em contato por meio do site.\n\n`;

  const urlWeb = `https://wa.me/555192440636?text=${encodeURI(bodyMessage)}`;
  const urlMobile = `whatsapp://send?text=${bodyMessage}&phone=555192440636`;

  return (
    <div className='c-footer'>
      <div className="c-footer__section">
        <img src={Logo} width="200" />
      </div>
      <div className="c-footer__section">
        <Href value='João Batista Silva' href='#'/>
        <Href value='CRECI 17.182' href='#'/>
        <Href value='Tramandaí - RS' href='#'/>
      </div>
      <div className="c-footer__section">
        <Href value='Entre em contato' href='#'/>
        <Href value='Encontrou algum problema?' href="mailto:carloswiebbelling@outlook.com?&bcc=carloswiebbelling@outlook.com&subject=Site Batista Corretor de Imóveis - Suporte&body=Olá, preciso da ajuda do suporte." />
        <Href value='Área do administrador' href='/login'/>
      </div>
      <div className="c-footer__section c-footer__section-social-media">
        <Href
          value={(
            <>
              <EmailIcon />
              {!isMobile ? 'Contato via e-mail' : null}
            </>
          )}
          href={`mailto:batistascorretor@gmail.com?&bcc=batistascorretor@gmail.com&subject=Site Batista Corretor de Imóveis - Contato via site&body=${bodyMessage}`}
        />
        <Href
          value={(
            <>
              <InstagramIcon />
              {!isMobile ? 'Contato via media social' : null}
            </>
          )}
          href='https://www.instagram.com/batista.corretordeimoveis/'
        />
        <Href
          value={(
            <>
              <WhatsIcon />
              {isMobile ? null : 'Contato via WhatsApp'}
            </>
          )}
          href={isMobile ? urlMobile : urlWeb}
          target="_blank"
        />
      </div>
    </div>
  );
}

export default Footer;
