import { ReactElement } from 'react';

import FormLogin from '../../organisms/Form/FormLogin';

const LoginPage = (): ReactElement => {

  return (
    <FormLogin />
  )
}

export default LoginPage;
