import axios, { AxiosError } from 'axios';

import { Client } from '../../static/types';
import { feedback } from './userFeedback';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BASE_URL = 'client';

const URI = `${ SERVER_URL }/${BASE_URL}`;

interface GetClientsParams {
  page?: number,
  limit?: number
}

const getClient =  async (id: string): Promise<Client | undefined> => {
  try {
    const response = await axios.get(`${ URI }/${id}`);
    return response.data as Client;
  } catch (error) {
    console.error(error);
  }
}

const getClients = async ({ page = 1, limit }: GetClientsParams) => {
  const params = {
    page,
    limit
  };

  try {
    const response = await axios.get(URI, { params });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const getClientsMetadata = async () => {
  try {
    const response = await axios.get(`${ URI }/metadata`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const createClient =  async (data: Client) => {
  try {
    const response = await axios.post(URI, data);

    feedback(response);

    return response;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

const updateClient =  async (data: Client) => {
  try {
    const { _id = '' } = data;
    const response = await axios.patch(`${ URI }/${_id}`, data);

    return response.data;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

const deleteClient =  async (id: string) => {
  try {
    const response = await axios.delete(`${ URI }/${ id }`);
    
    feedback(response);

    return 'response';
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

export {
  getClient,
  getClients,
  createClient,
  getClientsMetadata,
  updateClient,
  deleteClient
}
