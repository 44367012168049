import React, { ReactElement } from "react";

import { Pagination as MUIPagination } from "@mui/material";

import { PaginationProp, PaginationSize } from './types';

import './index.css';

const Pagination = (props: PaginationProp): ReactElement => {
  const {
    disabled,
    count,
    size = PaginationSize.MEDIUM,
    onChange,
    page
  } = props;
  
  return (
    <MUIPagination
      className="c-pagination"
      color="primary"
      count={count}
      page={page}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  )
}

export default Pagination;