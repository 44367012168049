import React, { ReactElement } from 'react';

import './index.css';

import PresentationImage from '../../static/images/presentation-img.jpg';

const Presentation = (): ReactElement => {
  return (
    <div className='c-presentation'>
      <img src={PresentationImage} />
      <div className="c-presentation__separator" />
      <div className="c-presentation__content">
        <label className="c-presentation__content-title">João Batista Silva</label>
        <label className="c-presentation__content-description">
          <label>
            Seja bem-vindo ao meu site!<br />
          </label>
          <label>
            Sou o Batista e exerço a profissão de Corretor de Imóveis desde 1998. 
            Com atuação em Tramandaí e regiões próximas, o meu objetivo é facilitar as transações imobiliárias para os meus clientes na hora de procurar por um imóvel.
          </label>
          <label>
            Nesse ano de 2022 inicio uma nova fase com a abertura da minha própria empresa. 
            Convido também você a iniciar uma nova fase em sua vida na busca pelo imóvel ideal na Batista Corretor de Imóveis.
          </label>
        </label>
        <div className="c-presentation__content-bottom">
          <label>
            CRECI 17.182
          </label>
          <label>
            Tramandaí - RS
          </label>
        </div>
      </div>
    </div>
  )
}

export default Presentation;
