import { ComboBoxOptions } from ".";

const getValues = (key: ComboBoxOptions) => {
  return {
    [ComboBoxOptions.WHATEVER]: undefined,
    [ComboBoxOptions.YES]: true,
    [ComboBoxOptions.NO]: false
  }[key];
}

const getKeys = (value?: boolean) => {
  if (typeof value === 'boolean' && value) {
    return ComboBoxOptions.YES;
  } else if (typeof value === 'boolean' && !value) {
    return ComboBoxOptions.NO;
  } else {
    return ComboBoxOptions.WHATEVER;
  }
}

export {
  getValues,
  getKeys
};
