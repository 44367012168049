import React, { ReactElement } from 'react';

import Footer from '../../molecules/Footer';
import Navbar from '../../molecules/Navbar'

import './index.css';

interface LayoutProps {
  children?: ReactElement | ReactElement[];
}

const Layout = (props: LayoutProps): ReactElement => {
  const { children } = props;

  return (
    <>
      <Navbar />
      <div className='c-layout'>
        {children}
      </div>
      <Footer />
    </>
  );
}

export default Layout;
