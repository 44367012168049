import { Query } from "../static/types"

const buildQuery = (data: Query): Record<string, unknown> => {
  // filter filled values
  const queryKeys = Object.keys(data).filter(key => data[key] !== undefined);
  
  if (!queryKeys.length) {
    return {};
  }
 
  const query = {};

  queryKeys.forEach(key => {
    if (key === 'price') {
      query['minPrice'] = data[key][0];
      query['maxPrice'] = data[key][1];
    } else {
      query[key] = data[key];
    }
  });

  return query;
}

export {
  buildQuery
}
