import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LayoutForm from '../Components/LayoutForm';

import { auth } from '../../../services/actions';
import { Auth } from '../../../static/types';
import { data, initialValues } from './form-data';

import { AuthContext } from '../../../context';

import Logo from '../../../static/images/card-visit-only-logo.png';

import './index.css';

const FormLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { setState } = useContext(AuthContext);

  // const navigate = useNavigate();

  const handleSubmit = (values: Auth) => {
    setLoading(true);
    auth(values)
      .then((result) => {
        setState({
          user: result,
          isAuthenticated: true
        });

        setLoading(false);
      });
  }

  return (
    <div className="c-login">
      <div className="c-login__form">
        <img className="c-login__logo" src={Logo} />
        <LayoutForm
          data={data}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          loading={loading}
          showClear={false}
          submitText={'Login'}
        />
      </div>
    </div>
  );
};

export default FormLogin;
