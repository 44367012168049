import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import format from 'format-string-by-pattern';

import TextField from '@mui/material/TextField';

import { InputProps, InputColor, InputType, InputSize, InputVariant } from './types';

import './index.css';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InputValuePrice = React.forwardRef<NumberFormat<number | string>, CustomProps>(
  function InputValuePrice(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        decimalSeparator=","
        thousandSeparator="."
        fixedDecimalScale={true}
        isNumericString
        prefix="R$ "
      />
    );
  },
);

const InputCPF = React.forwardRef<NumberFormat<number | string>, CustomProps>(
  function InputCPF(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={(inputValue: string) => format('999.999.999-99', inputValue)}
        isNumericString
      />
    );
  },
);

const InputRG = React.forwardRef<NumberFormat<number | string>, CustomProps>(
  function InputRG(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={(inputValue: string) => format('999.999.999.999', inputValue)}
        isNumericString
      />
    );
  },
);

const InputPhone = React.forwardRef<NumberFormat<number | string>, CustomProps>(
  function InputPhone(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={(inputValue: string) => format('(99) 9 9999-9999', inputValue)}
        isNumericString
      />
    );
  },
);

const InputCep = React.forwardRef<NumberFormat<number | string>, CustomProps>(
  function InputCep(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={(inputValue: string) => format('99999-999', inputValue)}
        isNumericString
      />
    );
  },
);

const Input = (props: InputProps): ReactElement => {

  const {
    autoFocus,
    className = '',
    color = InputColor.PRIMARY,
    defaultValue,
    disabled,
    error,
    id,
    label,
    max,
    name,
    onChange,
    placeholder,
    required,
    size = InputSize.MEDIUM,
    type = InputType.TEXT,
    value,
    variant = InputVariant.OUTLINED
  } = props;

  const classNames = `c-input ${className}`;

  const customProps = {
    InputProps: {
    ...(type === InputType.PRICE && {
      inputComponent: InputValuePrice as any,
    })
    },
    ...(name === 'cpf' && {
      inputComponent: InputCPF as any,
    }),
    ...(name === 'rg' && {
      inputComponent: InputRG as any,
    }),
    ...(name === 'phone' && {
      inputComponent: InputPhone as any,
    }),
    ...(name === 'cep' && {
      inputComponent: InputCep as any,
    }),
  }

  return (
    <>
      <TextField
        autoFocus={autoFocus}
        className={classNames}
        color={color}
        // defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        id={id}
        minRows={8}
        label={label}
        multiline={type === InputType.TEXTAREA}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        size={size}
        type={type}
        value={value}
        variant={variant}
        {...customProps}
      />
      {type === InputType.TEXTAREA && max && (
        <div className='c-input__length-helper'>
          {`(${ (value as string || '').length }/${ max })`}
        </div>
      )}
    </>
  );
}

export default Input;