import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toast";

import { AuthContextProvider } from './context';

import Routes from './Routes';

import './constants';
import './config/dept-dependency';
import './static/style/index.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#d3a63d",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes />
        </AuthContextProvider>
      </BrowserRouter>
      <ToastContainer delay={5000} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
