import { ReactElement } from 'react';

import { ReactComponent as Bathroom } from '../static/images/bathroom.svg';
import { ReactComponent as Bedroom } from '../static/images/bedroom.svg';
import { ReactComponent as Car } from '../static/images/car.svg';
import { ReactComponent as Email } from '../static/images/email.svg'
import { ReactComponent as Gps } from '../static/images/gps.svg';
import { ReactComponent as Pdf } from '../static/images/pdf.svg';
import { ReactComponent as Instagram } from '../static/images/instagram.svg';
import { ReactComponent as LeftArrowLight } from '../static/images/left-arrow-light.svg';
import { ReactComponent as LeftArrowThin } from '../static/images/left-arrow-thin.svg';
import { ReactComponent as Loading } from '../static/images/loading.svg'
import { ReactComponent as Metro } from '../static/images/metro.svg'
import { ReactComponent as Pet } from '../static/images/pet.svg';
import { ReactComponent as Plus } from '../static/images/plus.svg'
import { ReactComponent as Ruler } from '../static/images/ruler.svg';
import { ReactComponent as ThreeDots } from '../static/images/three-dots.svg'
import { ReactComponent as Whatsapp } from '../static/images/whatsapp.svg'
import { ReactComponent as X } from '../static/images/x.svg'

import { IconList } from '../static/types';

const getIcon = (name: IconList) => {
  const icon = {
    [IconList.BATHROOM]: Bathroom,
    [IconList.BEDROOM]: Bedroom,
    [IconList.CAR]: Car,
    [IconList.EMAIL]: Email,
    [IconList.FOOTAGE]: Ruler,
    [IconList.GARAGE]: Car,
    [IconList.GPS]: Gps,
    [IconList.INSTAGRAM]: Instagram,
    [IconList.LEFT_ARROW_LIGHT]: LeftArrowLight,
    [IconList.LEFT_ARROW_THIN]: LeftArrowThin,
    [IconList.LOADING]: Loading,
    [IconList.MIN_TO_METRO]: Metro,
    [IconList.PDF]: Pdf,
    [IconList.PET]: Pet,
    [IconList.PLUS]: Plus,
    [IconList.ROOM]: Bedroom,
    [IconList.RULER]: Ruler,
    [IconList.SUITE]: Bedroom,
    [IconList.THREE_DOTS]: ThreeDots,
    [IconList.WHATSAPP]: Whatsapp,
    [IconList.X]: X
  }[name];

  return icon;
}

export {
  getIcon
}
