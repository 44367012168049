import { ReactElement, useState, Fragment } from 'react';

import Modal from '@mui/material/Modal';

import { IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';
import FormClient from '../../organisms/Form/FormClient';

import './index.css';
import { InputSize } from '../Input';
import FormProperty from '../../organisms/Form/FormProperty';

interface SelectorModalProps {
  customCallback: () => void;
  endpoint?: string;
}

const SelectorModal = (props: SelectorModalProps): ReactElement | null => {
  const { customCallback, endpoint } = props;

  const [visibility, setVisibility] = useState<boolean>(false);
 
  const handleVisibility = () => {
    customCallback();
    setVisibility(!visibility);
  };

  const Icon = getIcon(IconList.PLUS);
 
  if (typeof endpoint !== 'string' || !endpoint.length) {
    return null;
  }

  const renderContent = (): ReactElement | undefined => {
    const Component = {
      ['client']: <FormClient customCallback={reqCustomCallback} size={InputSize.SMALL} />,
      ['proprieties']: <FormProperty customCallback={reqCustomCallback} size={InputSize.SMALL} />,
      ['property']: <FormProperty customCallback={reqCustomCallback} size={InputSize.SMALL} />,
    }[endpoint];

    return Component;
  }

  const reqCustomCallback = () => {
    handleVisibility();
  }

  return (
    <Fragment>
      <Icon className="c-selector-modal_visibiliy_btn" onClick={handleVisibility} />
      <Modal
        open={visibility}
        onClose={handleVisibility}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        component='div'
      >
        <div className='c-selector-modal'>
          {renderContent()}
        </div>
      </Modal>
    </Fragment>
  )
}

export default SelectorModal;
