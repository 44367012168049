import { ChangeEvent } from "react"
import { ImageProps } from "../../static/types"

enum InputColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

enum InputSize {
  MEDIUM = 'medium',
  SMALL = 'small'
}

enum InputType {
  BUTTON = 'button',
  CHECKBOX = 'checkbox',
  COLOR = 'color',
  DATE = 'date',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  NUMBER = 'number',
  PASSWORD = 'password',
  PRICE = 'price',
  RADIO = 'radio',
  RANGE = 'range',
  SELECT = 'select',
  SUBMIT = 'submit',
  TEXT = 'text',
  TEXTAREA = 'textarea'
}

enum InputVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  STANDARD = 'standard'
}

interface InputProps {
  autoFocus?: boolean;
  className?: string;
  color?: InputColor;
  defaultValue?: string | number | boolean | unknown;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  label: string;
  max?: number;
  name: string;
  onChange: (event: ChangeEvent<unknown>) => void;
  placeholder?: string;
  required?: boolean;
  size?: InputSize;
  type?: InputType;
  value?: string | number | boolean | Array<ImageProps> | ImageProps | unknown;
  variant?: InputVariant;
}

export {
  InputColor,
  InputSize,
  InputType,
  InputVariant
}

export type {
  InputProps
}
