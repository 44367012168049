import { ReactElement, useEffect, useState } from 'react';

import FilterBox from '../../molecules/FilterBox';

import PropertyList from '../../molecules/PropertyList';
import PropertySlider from '../../organisms/PropertySlider';
import Presentation from '../../organisms/Presentation';

import { PropertyCardMode } from '../../molecules/PropertyCard';
import { isMobileScreen } from '../../utils/screen';

import { Property } from '../../static/types';
import { getProperties } from '../../services/actions';

import './index.css';

const Home = (): ReactElement => {
  const [propertyList, setPropertyList] = useState<Array<Property>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchProperty();
  }, []);

  const fetchProperty = () => {
    setLoading(true);

    getProperties({ limit: 8 })
      .then(propertyList => {
        setPropertyList(propertyList ?? []);
        setLoading(false);
      });
  }

  const handleSearch = (values: any) => {
    // console.log(values);
  }

  return (
    <div className='p-home'>
      <div className='p-home__layout'>
        {!isMobileScreen() && (
          <PropertySlider
            loading={loading}
            data={propertyList}
          />
        )}
        <FilterBox isBox onSubmit={handleSearch} />
      </div>
      <PropertyList
        renderSeeMore
        short
        loading={loading}
        data={propertyList}
        mode={PropertyCardMode.VERTICAL}
      />
      <Presentation />
    </div>
  )
}

export default Home;
