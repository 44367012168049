import { ReactElement } from 'react';

import { IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';

import './index.css';

const Loading = (): ReactElement => {
  const LoadingIcon = getIcon(IconList.LOADING);

  return (
    <div className='c-loading'>
      <LoadingIcon className="c-loading__svg" />
    </div>
  );
}

export default Loading;