import axios, { AxiosError } from 'axios';

import { Property, Query } from '../../static/types';
import { buildQuery } from '../../utils/query';

import { feedback } from './userFeedback';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BASE_URL = 'search';

const URI = `${ SERVER_URL }/${BASE_URL}`;

const query = async (data: Query): Promise<Array<Property> | undefined> => {
  try {
    const params = buildQuery(data);
    const response = await axios.get(URI, { params });

    return response.data;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

export {
  query
}
