import React, { ReactElement } from 'react';

import {
  Paper,
  Popover,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { TableProps } from './types';
import { getIcon } from '../../utils/getIcon';
import { Client, IconList } from '../../static/types';

import './index.css';
import Loading from '../Loading';

interface PopoverReference {
  ref?: HTMLButtonElement;
  id?: string;
}

const Table = (props: TableProps): ReactElement => {
  const {
    data = [],
    headers,
    id,
    popoverContent,
    loading,
    renderTableRow,
  } = props;

  const [popoverRef, setPopoverRef] = React.useState<PopoverReference>();

  const renderHeader = (): ReactElement => {
    return (
      <TableRow>
        {headers.map((cell: string) => (
          <TableCell key={cell}>{cell}</TableCell>
        ))}
        {popoverContent && <TableCell />}
      </TableRow>
    );
  }

  const handlePopoverAnchor = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setPopoverRef({
      ref: event.currentTarget,
      id,
    });
  };

  const handleClosePopover = () => {
    setPopoverRef({
      ref: undefined,
      id: undefined
    });
  };

  const renderPopoverMenu = (_id: string): ReactElement => {
    return (
      <Popover
        open={Boolean(popoverRef?.ref) && popoverRef?.id === _id}
        onClose={handleClosePopover}
        anchorEl={popoverRef?.ref}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverContent && popoverContent(_id)}
      </Popover>
    )
  }

  const renderContent = (): ReactElement[] => {
    const ThreeDotsIcon = getIcon(IconList.THREE_DOTS);

    return data.map((row: Client, index) => {
      const className = `c-table__row ${ index % 2 === 0 ? 'c-table__row-even' : 'c-table__row-odd' } ${ popoverContent && 'c-table__popover' }`;

      return (
        <TableRow key={row._id} className={className}>
          {renderTableRow(row, index)}
          {popoverContent && (
            <TableCell>
              <ThreeDotsIcon
                className="c-table__popover"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handlePopoverAnchor(event, row._id)}
              />
              {renderPopoverMenu(row._id)}
            </TableCell>
          )}
        </TableRow>
      );
    });
  };

  return (
    <TableContainer id={id} component={Paper} className='c-table'>
      <MUITable aria-label="caption table">
        <TableHead className="c-table__cell-header">
          {!loading && data.length > 0 && renderHeader()}
        </TableHead>
        <TableBody>
          {!loading && renderContent()}
        </TableBody>
      </MUITable>
      {loading && <Loading />}
      {!loading && !data.length && (
        <div className='c-table__no-content'>
          Sem conteúdo para exibir.
        </div>
      )}
    </TableContainer>
  );
}

export default Table;