import axios, { AxiosError } from 'axios';

import { Auth, Client } from '../../static/types';
import { feedback } from './userFeedback';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BASE_URL = 'login';

const URI = `${ SERVER_URL }/${BASE_URL}`;

const auth = async (data: Auth): Promise<Client | undefined> => {
  try {
    const response = await axios.post(URI, data);   

    localStorage.setItem('auth', 'isAuthenticated');

    return response.data;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }

    return undefined;
  }
}

const logout = async (): Promise<any> => {
  try {
    const response = await axios.get(`${ SERVER_URL }/logout`);

    localStorage.removeItem('auth');
    document.location.reload();

    return response;
  } catch (error) {
    const err = (error as AxiosError).response;
    localStorage.removeItem('auth');

    if (err) {
      feedback(err);
    }

    return undefined;
  }
}

export {
  auth,
  logout
}
