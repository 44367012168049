import React, { ReactElement, Fragment, ChangeEvent, useState, useEffect } from 'react';

import Checkbox from '../../atoms/Checkbox';

import { ImageUploaderProps, ImagePreviewProps } from './types';
import { ImageProps } from '../../static/types';

import './index.css';

const ImagePreview = (props: ImagePreviewProps) => {
  const {
    id,
    isBanner,
    removeImage,
    name,
    setBanner,
    src,
  } = props;

  const checkboxClassNames = `c-image-uploader__main-image-box ${ isBanner && 'c-image-uploader__main-image' }`

  const imagePreviewClassNames = `c-file-uploader__image-preview ${Boolean(isBanner) && 'banner-image'}`;

  return (
    <div className={imagePreviewClassNames}>
      <img src={src} />
      <div
        className='c-file-uploader__image-preview__delete'
        onClick={() => removeImage(id)}
      />
      <Checkbox
        checked={Boolean(isBanner)}
        className={checkboxClassNames}
        label="Banner"
        name={name}
        onChange={() => setBanner(id)}
      />
    </div>
  )
}

const ImageUploader = (props: ImageUploaderProps): ReactElement => {
  const {
    onChange,
    values = []
  } = props;

  const [images, setImages] = useState<Array<ImageProps>>([]);
  const [bannerId, setBannerId] = useState<string | number | undefined>(undefined);

  useEffect(() => {
    if (!images.length && values.length) {
      setImages(values);
    }
  }, [values, setImages]);

  const getImageName = (file: File): string => {

    const lastIndex = file.name.lastIndexOf('.');

    const now = new Date().getTime();
    const name = `${file.name.substring(0, lastIndex)}__${now}${file.name.substring(lastIndex)}`

    return name;
  }

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      const newFile: ImageProps = {
        file,
        id: new Date().getTime(),
        isBanner: false,
        name: getImageName(file),
      }

      const imageData = [ ...images, newFile];

      setImages(imageData);
      onChange(imageData);
    }
  }

  const removeImage = (id: string | number): void => {
    const newImagesData = images.filter(image => image.id !== id);

    setImages(newImagesData);
    onChange(newImagesData);

    if (bannerId === id) {
      resetBanner();
    }
  }

  const setBanner = (id: string | number | undefined): void => {
    if (bannerId === id) {
      resetBanner();
    } else {
      handleSetBanner(id);
    }
  }

  const handleSetBanner = (id: string | number | undefined): void => {
    if (!id) return;

    const newImageData = images.map(image => ({ ...image, isBanner: image.id === id }));
    
    setImages(newImageData);
    onChange(newImageData);
    setBannerId(id);
  }
 
  const resetBanner = (): void => {
    const newImageData = images.map(image => ({ ...image, isBanner: false }));
    
    setBannerId(undefined);
    setImages(newImageData);
    onChange(newImageData);
  }

  const getImageSrc = (file: File | string): string => {
    if (typeof file === 'string') {
      return file;
    }

    return URL.createObjectURL(file);
  }

  const renderImages = (): ReactElement[] | null => {
    return images.map((image: ImageProps) => (
      <ImagePreview
        id={image.id}
        isBanner={image.isBanner}
        removeImage={removeImage}
        setBanner={setBanner}
        src={getImageSrc(image.file)}
      />
    ));
  }

  return (
    <div className='c-image-uploader'>
      {renderImages()}
      <input
        type="file"
        id="file-uploader"
        name="filename"
        hidden
        onChange={onImageChange}
      />
      <label
        htmlFor="file-uploader"
        className='c-image-uploader__upload-btn' 
      />
    </div>
  );
}

export default ImageUploader;
