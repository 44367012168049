import React, { useRef, ReactElement, useEffect, useState, TouchEvent, useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import Button, { ButtonColor, ButtonSize, ButtonVariant } from '../../atoms/Button';
import ScreenLocker from '../../atoms/ScreenLocker';

import { isMobileScreen } from '../../utils/screen';
import { AuthContext } from '../../context';
import { logout } from '../../services/actions';

import Logo from '../../static/images/card-visit-only-logo.png';

import './index.css';

const Navbar = (): ReactElement => {
  const navigate = useNavigate();
  
  let touchStartPosition = 0;

  const drawerRef = useRef<HTMLDivElement>(null);

  const { state } = useContext(AuthContext);

  const [isDrawerOpen, setDrawerOpen] = useState<Boolean>(false);
  const [isClosing, setIsClosing] = useState<Boolean>(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  const openDrawerMenu = (): void => {
    setDrawerOpen(true);
  }

  const closeDrawerMenu = (): void => {
    if (!isMobileScreen()) {
      return;
    }

    setIsClosing(true);

    setTimeout(() => {
      setDrawerOpen(false);
      setIsClosing(false);
    }, 250);
  }

  const handleClickOutside = (event: MouseEvent): void => {
    if (drawerRef.current && !event.composedPath().includes(drawerRef.current)) {
      closeDrawerMenu();
    }
  }

  const getLinks = (): ReactElement => {
    return (
      <>
        <Link to="/" onClick={closeDrawerMenu}>Início</Link>
        <Link to="/" onClick={closeDrawerMenu}>Contato</Link>
        {state.isAuthenticated && (
          <>
            <Link to="/clients" onClick={closeDrawerMenu}>Clientes</Link>
            <Link to="/properties" onClick={closeDrawerMenu}>Imóveis</Link>
            <Link to="/properties/add" onClick={closeDrawerMenu}>Adicionar imóveis</Link>
            {isMobileScreen()
              ? (
                <>
                  <Link to="/clients" onClick={closeDrawerMenu}>Imóveis</Link>
                  <Link to="#" className='navbar-logout-link' onClick={() => logout()}>Sair</Link>
                </>
              ) : (
                <>
                  <Button
                    value="logout"
                    onClick={() => logout()}
                    variant={ButtonVariant.OUTLINED}
                    color={ButtonColor.ERROR}
                  />
                  <Button className="c-navbar__property-btn" onClick={() => navigate("/")} size={ButtonSize.MEDIUM} value="Imóveis" />
                </>
              )
            }
          </>
        )}
      </>
    );
  }
  
  const renderNavbarWeb = (): ReactElement => {
    return (
      <nav className="c-navbar">
        <img className="c-navbar__logo" src={Logo} />
        <div className="c-navbar__content">
          {getLinks()}
        </div>
        <Outlet />
      </nav>
    );
  }

  const renderNavbarMobile = (): ReactElement => {
    return (
      <div className='c-navbar__drawer-navbar'>
        <img className="c-navbar__logo" src={Logo} />
        <div onClick={openDrawerMenu}>
          <MenuIcon />
          {renderDrawer()}
        </div>
      </div>
    );
  }

  const onTouchStart = (event: TouchEvent<HTMLDivElement>): void => {
    touchStartPosition = event.touches[0].clientX;
  }
  
  const onTouchEnd = (event: TouchEvent<HTMLDivElement>): void => {
    if (!isMobileScreen()) {
      return;
    }

    const dragEnd = event.changedTouches[0].clientX;
    const validMovement = dragEnd > touchStartPosition && dragEnd - touchStartPosition >= 50;

    if (validMovement) {
      closeDrawerMenu();
    }
  }

  const renderDrawer = (): ReactElement | null => {
    if (!isDrawerOpen) {
      return null;
    }

    const classNames = `c-navbar__drawer ${isClosing ? 'c-navbar__drawer-closing' : ''}`

    return (
      <ScreenLocker>
        <div
          className={classNames}
          ref={drawerRef}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        >
          {getLinks()}
        </div>
      </ScreenLocker>
    );
  }

  return isMobileScreen() ? renderNavbarMobile() : renderNavbarWeb();
}

export default Navbar;
