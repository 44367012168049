import { ReactElement } from 'react';
import { Link as MUIlink } from '@mui/material';

import { HrefProps } from './types';

import './index.css';

const Href = (props: HrefProps): ReactElement => {
  const {
    href,
    value,
    target
  } = props;

  return (
    <MUIlink className="c-href" href={href} target={target} underline='none'>{value}</MUIlink>
  );
}

export default Href;