import { InputType } from "../../../atoms/Input";

import { isMobileScreen } from '../../../utils/screen';

const isMobile = isMobileScreen();

const data = [
  {
    title: "Informações do cliente",
    fields: [
      {
        label: 'Primeiro nome',
        // min: 3,
        // max: 20,
        name: 'firstName',
        required: true,
        type: InputType.TEXT,
        width: isMobile ? 12 : 4
      },
      {
        label: 'Sobrenome',
        // min: 3,
        name: 'lastName',
        // required: true,
        type: InputType.TEXT,
        width: isMobile ? 12 : 4
      },
      {
        label: 'Legenda',
        name: 'label',
        // min: 0,
        type: InputType.TEXT,
        width: isMobile ? 12 : 4
      },
      {
        label: 'CPF',
        // min: 11,
        name: 'cpf',
        type: InputType.TEXT,
        width: 6
      },
      {
        label: 'RG',
        // min: 11,
        name: 'rg',
        type: InputType.TEXT,
        width: 6
      },
      {
        label: 'Rua, avenia, travessa',
        name: 'street',
        type: InputType.TEXT,
        // min: 5,
        width: 9
      },
      {
        label: 'Bairro',
        name: 'district',
        type: InputType.TEXT,
        // min: 5,
        width: 3
      },
      {
        label: 'Cidade',
        name: 'city',
        type: InputType.TEXT,
        // min: 5,
        width: 4
      },
      {
        label: 'Estado',
        // min: 2,
        name: 'state',
        type: InputType.TEXT,
        width: 4
      },
      {
        label: 'CEP',
        name: 'cep',
        type: InputType.TEXT,
        width: 4
      }
    ]
  },
  {
    title: "Informações gerais",
    fields: [
      {
        label: 'Campo livre',
        name: 'content',
        type: InputType.TEXTAREA,
        // min: 0,
        max: 500,
        width: 12
      }
    ]
  },
  {
    title: "Informações de contato",
    fields: [
      {
        label: 'Email',
        name: 'email',
        // min: 0,
        type: InputType.EMAIL,
        width: isMobileScreen() ? 12 : 6
      },
      {
        label: 'Telefone',
        name: 'phone',
        // min: 0,
        type: InputType.TEXT,
        width: 6
      }
    ]
  },
];

const initialValues = {};

export {
  data,
  initialValues
}
