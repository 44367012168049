import { ReactElement } from 'react';
import Button, { ButtonType, ButtonVariant } from '../../../../atoms/Button';

import './index.css';

interface FormActionsProps {
  className?: string,
  clearText?: string;
  handleReset: any,
  loading?: boolean
  showClear?: boolean;
  onSubmit?: () => void;
  submitText?: string;
}

const FormActions = (props: FormActionsProps): ReactElement => {
  const {
    className = '',
    clearText = 'Limpar',
    handleReset,
    loading,
    onSubmit,
    showClear = true,
    submitText = 'Cadastrar',
  } = props;

  const customClassName = `c-form-actions ${className}`;

  return (
    <div className={customClassName}>
      {showClear && (
        <Button
          value={clearText}
          onClick={handleReset}
          type={ButtonType.BUTTON}
          variant={ButtonVariant.TEXT}
        />
      )}

      <Button
        value={submitText}
        loading={loading}
        onClick={() => onSubmit && onSubmit()}
        type={ButtonType.SUBMIT}
      />
    </div>
  )
}

export default FormActions;
