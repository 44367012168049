import { ReactElement, useState } from 'react';

import PropertyCard, { PropertyCardMode } from '../../molecules/PropertyCard';
import { getIcon } from '../../utils/getIcon';

import { isMobileScreen } from '../../utils/screen';
import { IconList, Property } from '../../static/types';

import './index.css';
import Loading from '../../atoms/Loading';
import NoContent from '../../atoms/NoContent';

interface PropertiesListProps {
  data: Array<Property>;
  loading?: boolean;
}

const PropertySlider = (props: PropertiesListProps): ReactElement => {
  const {
    data,
    loading
  } = props;

  const [currentStep, setCurrentStep] = useState(0);

  const renderCurrentStep = (): ReactElement | null => {
    if (!data.length) {
      return <NoContent />;
    }

    const {
      _id,
      city,
      condominium,
      description,
      district,
      bannerImage,
      iptu,
      price,
      seilType,
      state,
      street,
      title,
      immobilieType,
      acceptPet,
      hasEletricity,
      hasPool,
      hasWater
    } = data[currentStep];

    return (
      <div className='c-property-slider__property'>
        <img src={String(bannerImage?.file)} />
        <div className='c-property-slider__box'>
          <PropertyCard
            street={street}
            description={description}
            _id={_id}
            city={city}
            district={district}
            state={state}
            bannerImage={bannerImage}
            condominium={condominium}
            isBox
            short
            iptu={iptu}
            mode={PropertyCardMode.VERTICAL}
            price={price}
            seilType={seilType}
            showImage={false}
            title={title}
            immobilieType={immobilieType}
            acceptPet={acceptPet}
            hasEletricity={hasEletricity}
            hasPool={hasPool}
            hasWater={hasWater}
          />
        </div>
      </div>
    )
  }

  const handleStepChange = (index: number): void => {
    setCurrentStep(index);
  }

  const renderSteps = (): ReactElement | null => {
    if (!data.length || isMobileScreen()) {
      return null;
    }

    const Icon = getIcon(IconList.GPS)

    return (
      <div className='c-property-slider__steps'>
        {data.slice(0, 4).map((property, index) => {
          const classNames = `c-property-slider__step ${currentStep === index && 'c-property-slider__active'}`;

          return (
            <div className={classNames} onClick={() => handleStepChange(index)} key={index}>
              <span className='c-property-slider__step-title'>{property.title}</span>
              <span className='c-property-slider__step-description'>{property.description}</span>
              <div className='c-property-slider__step-address-box'>
                <Icon />
                <span className='c-property-slider__step-address'>{property.street}</span>
              </div>
            </div>
          );
        })}
      </div>
    )
  }

  return (
    <div className="c-property-list">
      {loading
        ? <Loading />
        : (
          <>
            {renderCurrentStep()}
            {renderSteps()}
          </>
        )}
    </div>
  )
}

export default PropertySlider;
