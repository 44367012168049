const handleBigText = (text: string): string => {
  if (text.length > 50) {
    return `${text.slice(0, 50)}...`;
  }

  return text;
}

export {
  handleBigText
}