const translate = (key: string): string => {
  const data = {
    // common
    ['bathroom']: 'banheiro',
    ['bathrooms']: 'banheiro',
    ['whatever']: 'tanto faz',
    ['yes']: 'sim',
    ['no']: 'não',
    ['sell']: 'venda',
    ['car']: 'garagem',
    ['bedroom']: 'quarto',
    ['room']: 'quarto',
    ['ruler']: 'm2',
    ['rent']: 'alugel',
    ['garage']: 'garagem',
    ['footage']: 'metragem',
    ['minSubway']: 'min ao metro',

    // data
    ['firstName']: 'nome',
    ['lastName']: 'sobrenome',
    ['label']: 'etiqueta',
    ['cpf']: 'CPF',
    ['rg']: 'RG',
    ['phone']: 'telefone',
    ['email']: 'email',
    ['street']: 'rua',
    ['district']: 'bairro',
    ['state']: 'estado',
    ['city']: 'cidade',
    ['cep']: 'CEP',
    ['content']: 'conteúdo livre',
  }[key];

  return data || key;
}

export {
  translate
}
