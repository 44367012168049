const headerColumns = [
  'Nome',
  'Endereço',
  'Label',
  'Data',
  'Telefone',
  'Email',
  'Campo livre (prévia)'
];

export { headerColumns };
