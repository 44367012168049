import React, { ReactElement } from 'react';

import { ComboBoxOptions, ComboBoxProps } from './types';
import { translate } from '../../utils/translate';

import './index.css';

const ComboBox = (props: ComboBoxProps) => {
  const {
    label,
    defaultValue = ComboBoxOptions.WHATEVER,
    value: selectedValue,
    name,
    onChange
  } = props;

  const onChangeValue = (value: ComboBoxOptions) => {
    onChange({ key: name, value });
  }

  const renderOptions = (): ReactElement[] => {
    return Object.values(ComboBoxOptions).map(value => {
      const className = `c-combo-box__value ${(selectedValue ?? defaultValue) === value && 'is-selected'}`

      return (
        <div key={value} className={className} onClick={() => onChangeValue(value)}>{ translate(value) }</div>
      );
    })
  }

  return (
    <div className='c-combo-box'>
      <label className='c-combo-box__label'>
        {label}
      </label>
      <div className='c-combo-box__values'>
        {renderOptions()}
      </div>
    </div>
  );
}

export default ComboBox;
