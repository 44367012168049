import React, { ReactElement } from 'react';

import { InfoBadgeProps } from './types';
import { getIcon } from '../../utils/getIcon';
import { IconList } from '../../static/types';

import './index.css';

const InfoBadge = (props: InfoBadgeProps): ReactElement => {
  const { icon, value, label } = props;

  const renderIcon = (): ReactElement => {
    const Icon = getIcon(icon as IconList);
    return <Icon />
  }

  const classNames = `c-info-badge ${ label && 'c-info-badge__with-label' }`;

  return (
    <div className={classNames}>
      {label && <label className='c-info-badge__label'>{label}</label>}
      <div>
        {renderIcon()}
        <span>{value}</span>
      </div>
    </div>
  )
}

export default InfoBadge;
