import React, { ReactElement } from 'react';

import Slider from '@mui/material/Slider';

import { PriceSliderProps } from './types';

import './index.css';

const PriceSlider = (props: PriceSliderProps): ReactElement => {
  const {
    value,
    onChange,
    min = 1500,
    max = 100000,
  } = props;

  const handleChange = (_event: Event, newValue: number | Array<number>, _activeThumb: number) => {
    onChange(newValue as Array<number>);
  }

  const formatValue = (value: number, index: number): string => {
    return `R$ ${value}`;
  }

  return (
    <Slider
      className="c-price-slider"
      onChange={handleChange}
      valueLabelFormat={formatValue}
      value={value}
      disableSwap
      valueLabelDisplay="on"
      min={min}
      step={200}
      max={max}
    />
  )
}

export default PriceSlider;
