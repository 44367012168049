import { ReactElement, useEffect, useState, useCallback } from 'react';

import PropertyList from '../../molecules/PropertyList';
import FilterBox from '../../molecules/FilterBox';
import Loading from '../../atoms/Loading';

import { getProperties, query } from '../../services/actions';
import { PropertyCardMode } from '../../molecules/PropertyCard';
import { Property } from '../../static/types';
import { isMobileScreen } from '../../utils/screen';

import './index.css';

const PropertyListLayout = (): ReactElement => {
  const [propertyList, setPropertyList] = useState<Array<Property> | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(false);

  const [searchValues, setSearchValues] = useState({});

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(2);

  useEffect(() => {
    fetchProperty();
  }, [page, limit]);

  const fetchProperty = useCallback(async() => {
    setLoading(true);
    
    await getProperties({ page, limit })
      .then(propertyList => setPropertyList(propertyList ?? []))
      .finally(() => setLoading(false));
  }, [page, limit]);

  const handleSearch = async (values: any) => {
    setLoading(true);
    setSearchValues(values);

    await query(values).then(propertyList => {
      setPropertyList(propertyList ?? []);
      setLoading(false);
    });
  }

  const getClassNames = () => {
    return `${ isMobileScreen() ? '' : 'd-flex'} p-properties`;
  }

  return (
    <div className={getClassNames()}>
      <FilterBox onSubmit={handleSearch} defaultValues={searchValues} />
      <div className='p-properties__list'>
        {loading 
          ? <Loading />
          : <PropertyList
              page={page}
              setPage={setPage}
              isPropertyList
              data={propertyList}
              mode={PropertyCardMode.HORIZONTAL}
            />
        }
      </div>
    </div>
  )
}

export default PropertyListLayout;
