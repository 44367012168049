import { AxiosResponse } from 'axios';
import { toast } from 'react-toast';

const messages = (status: number, errors: string) => {
  const response = {
    [200]: () => toast.success("Ação feita com sucesso!"),
    [300]: () => toast.warn("Usário não autenticado"),
    [400]: () => toast.error("Erro: " + errors),
    [401]: () => toast.error("Erro: " + errors)
  }[status];

  return response;
}

const feedback = (response: AxiosResponse) => {
  const toast = messages(response.status, response.data.errors.toString());

  toast && toast();
}

export {
  feedback
}
