import AWS from 'aws-sdk';
import { ManagedUpload } from "aws-sdk/clients/s3";

import { ImageProps } from "../../static/types"

interface ImagesUploaded {
  bannerImage?: ImageProps;
  images?: Array<ImageProps>;
}

AWS.config.update({ region: 'us-west-2' });

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: 'us-west-2',
  apiVersion: '2006-03-01'
});

const uploadImages = async (data: Array<ImageProps>): Promise<ImagesUploaded> => {
  if (!data || !data.length) {
    return {};
  }

  // filter images not uploaded yet
  const fileData = data.filter(image => typeof image.file === 'object');
  const filesAlreadyUploaded = data.filter(image => typeof image.file === 'string');

  const images: Array<ImageProps> = filesAlreadyUploaded;

  const promises = fileData.map(async image => {
    const uploadParams = {
      Bucket: process.env.REACT_APP_S3_NAME ?? 'immobilie',
      Key: image.name,
      Body: image.file,
    }

    try {
      const response: ManagedUpload.SendData = await s3.upload(uploadParams).promise();

      images.push({ ...image, file: response.Location });
    } catch (error) {
      console.error(error);
    }
  })

  await Promise.all(promises);

  const bannerImage: ImageProps | undefined = images.find(image => image.isBanner) ?? images[0];

  return {
    bannerImage: bannerImage ?? images[0],
    images
  };
}

export {
  uploadImages
}