interface NoContentPropss {
  className?: string;
}

const NoContent = (props: NoContentPropss) => {
  const { className } = props;

  const classNames = `c-no-content ${className}`;

  return (
    <div className={classNames}>
      Sem conteúdo para exibir.
    </div>
  )
}

export default NoContent;
