import React, { ReactElement, Fragment, useState } from 'react';

import PropertyCard from '../PropertyCard';
import { PropertiesListProps } from './types';

import { Link } from '@mui/material';
import { IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';

import './index.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../../atoms/Loading';
import { isMobileScreen } from '../../utils/screen';
import NoContent from '../../atoms/NoContent';
import Pagination from '../../atoms/Pagination';

const PropertiesList = (props: PropertiesListProps): ReactElement => {
  const {
    data = [],
    isPropertyList,
    loading,
    mode,
    renderSeeMore,
    short,
    page,
    setPage
  } = props;

  const navigate = useNavigate();

  const Icon = getIcon(IconList.LEFT_ARROW_THIN);

  const renderList = () => {
    return (
      <div className="c-property-list">
        {!data.length && !loading && <NoContent />}
        {data.map((property) => (
          <PropertyCard
            _id={property._id}
            acceptPet={property.acceptPet}
            bannerImage={property.bannerImage}
            bathroom={property.bathroom}
            cep={property.cep}
            city={property.city}
            condominium={property.condominium}
            description={property.description}
            district={property.district}
            footage={property.footage}
            garage={property.garage}
            hasEletricity={property.hasEletricity}
            hasPool={property.hasPool}
            hasWater={property.hasWater}
            immobilieType={property.immobilieType}
            iptu={property.iptu}
            isPropertyList={isPropertyList}
            key={property._id}
            minSubway={property.minSubway}
            mode={mode}
            price={property.price}
            room={property.room}
            seilType={property.seilType}
            short={short || isMobileScreen()}
            state={property.state}
            street={property.street}
            suite={property.suite}
            title={property.title}
          />
          )
        )}
        <Pagination
          page={page || 1}
          count={100}
          onChange={(_event, page) => setPage && setPage(page)}
        />
      </div>
    )
  }

  return (
    <Fragment>
      {renderSeeMore && (
        <Link
          className="c-property-list__see-more"
          component="button"
          variant="body2"
          underline="none"
          onClick={() => navigate('/properties')}
        >
          ver mais <Icon />
        </Link>
      )}
      {loading
        ? (
          <div className='c-property-list__loading'>
            <Loading />
          </div>
        )
        : renderList()
      }
    </Fragment>
  )
}

export default PropertiesList;
