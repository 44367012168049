import { InputType } from '../../../../atoms/Input';
import { ImageProps } from '../../../../static/types';
import type { FormData, FormError } from './types';

type Object = Record<string, string | number>;

const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const validator = (values: Record<string, string | boolean | number | ImageProps[]>, form: Array<FormData>): FormError => {
  const mappedFields: Array<Object> = [];
  const requiredFields: Array<Object> = [];
  const filledFields = Object.keys(values);

  const errors: FormError = {};

  // save map fields
  form.forEach(sections =>
    sections.fields.forEach(field => {
      const mapField = {
        field: field.name,
        label: field.label,
        type: field.type,
        ...( typeof field.min !== 'undefined' && { min: field.min }),
        ...( typeof field.max !== 'undefined' && { max: field.max })
      };

      mappedFields.push(mapField);
      field.required && requiredFields.push(mapField);
    })
  );

  mappedFields.forEach((field: Object) => {
    const isRequired = requiredFields.find(requiredField => requiredField.field === String(field.field));

    errors[field.field] = [];

    // check missing required fields
    if (isRequired && !filledFields.includes(String(field.field))) {
      errors[field.field].push(`${field.label} é obrigatório`);
    }

    const value = values[field.field];

    const textType = field.type === InputType.TEXT || field.type === InputType.TEXTAREA;

    if (typeof field.min !== 'undefined') {
      if (field.type === InputType.NUMBER && (!value && field.min || value < field.min)) {
        errors[field.field].push(`Precisa ser no mínimo ${ field.min }.`);
      } else if (textType && (!value && field.min || String(value).length < field.min)) {
        errors[field.field].push(`Possui tamanho mínimo de ${ field.min } caracteres.`);
      }
    }
    
    if (field.max) {
      if (field.type === InputType.NUMBER && value > field.max) {
        errors[field.field].push(`Precisa ser no máximo ${ field.max }.`);
      } else if (textType && String(value).length > field.max) {
        errors[field.field].push(`Possui tamanho máximo de ${ field.max } caracteres.`);
      }
    }

    if (value && field.type === InputType.EMAIL && !emailReg.test(String(value))) {
      errors[field.field].push(`Formato de email inválido`);
    }
  });

  return errors;
}

export default validator;
