import React, { ReactElement } from "react";

import { Button as MUIButton } from "@mui/material";

import { ButtonProp, ButtonVariant, ButtonType, ButtonColor, ButtonSize } from './types';

import './index.css';
import Loading from "../Loading";

const Button = (props: ButtonProp): ReactElement => {
  const {
    className = '',
    color = ButtonColor.PRIMARY,
    disabled,
    endIcon,
    onClick,
    size = ButtonSize.MEDIUM,
    startIcon,
    type = ButtonType.BUTTON,
    value,
    loading,
    variant = ButtonVariant.CONTAINED,
  } = props;

  return (
    <MUIButton
      className={`c-button ${className}`}
      color={color}
      disabled={loading || disabled}
      endIcon={endIcon}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      type={type}
      value={value}
      variant={variant}
    >
      <>
        {loading && <Loading />}
        {value}
      </>
    </MUIButton>
  )
}

export default Button;