import React, { ReactElement } from "react";

import { LabelProp } from './types';

import './index.css';

const Label = (props: LabelProp): ReactElement => {
  const {
    title,
    value
  } = props;
  
  return (
    <div className="c-label">
      <label className="c-label__title">{title}</label>
      <label className="c-label__value">{value ?? ''}</label>
    </div>
  )
}

export default Label;