import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { Select as MUISelect, MenuItem, InputLabel, FormControl } from '@mui/material';

import SelectorModal from './SelectorModal';

import { getClients, getProperties } from '../../services/actions';
import { SelectProps } from './types';
import { SelectOption } from '.';

import { IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';

import './index.css';

const Select = (props: SelectProps): ReactElement => {
  const {
    className,
    endpoint,
    label,
    onChange,
    options: propOptions,
    value,
  } = props;

  const [options, setOptions] = useState<Array<SelectOption>>([]);
  const [shouldFetchUser, setShouldFetchUser] = useState<boolean>(true);
  
  useEffect(() => {
    if (!shouldFetchUser || options.length) return;

    if (propOptions.length) {
      setOptions(propOptions);
      setShouldFetchUser(false);

      return;
    } 
    
    if (typeof endpoint === 'string' && endpoint.length) {
      buildOptions();
      setShouldFetchUser(false);

      return;
    }

  }, [endpoint, shouldFetchUser, setShouldFetchUser, propOptions]);
  
  const getOptionsByEndpoint = async (endpoint: string): Promise<Array<Record<string, any>>| undefined> => {
    const fetch = {
      ['client']: () => getClients({ }),
      ['property']: () => getProperties(),
    }[endpoint] || undefined;

    return fetch ? await fetch() : [];
  }

  const renderOptions = (): ReactElement[] => {
    return options.map((option: SelectOption, index: number) => (
      <MenuItem key={index} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  const buildOptions = async (): Promise<void> => {
    if (!endpoint) return;

    const options: Array<Record<string, any>> = await getOptionsByEndpoint(endpoint) || [];

    const getLabel = (item: Record<string, string>): string => {
      const label = {
        ['client']: `${item.firstName} ${item.lastName || ''}`,
        ['properties']: item.title
      }[endpoint] || '';

      return label;
    }

    const mappedValues: Array<SelectOption> = options.map(item => 
      ({
        label: getLabel(item),
        value: item._id
      }));
    
    setOptions(mappedValues);
  }

  const customClassName = `c-selector ${className} ${(endpoint || '').length && 'c-select__compact'}`;

  return (
    <FormControl className={customClassName}>
      <InputLabel id="select-label">{label}</InputLabel>
      <MUISelect
        labelId="select-label"
        id="select"
        value={value}
        label={label}
        onChange={onChange}
        style={{ maxHeight: "20rem" }}
      >
        <MenuItem value="">
          <em>Sem valor</em>
        </MenuItem>
        {renderOptions()}
      </MUISelect>
      <SelectorModal
        endpoint={endpoint}
        customCallback={() => buildOptions()}
      />
    </FormControl>
  )
}

export default Select;
