import { ReactElement } from 'react';

import FormProperty from '../../organisms/Form/FormProperty';

const AddProperty = (): ReactElement => {

  return (
    <FormProperty customCallback={() => 'document.location.reload()'} />
  )
}

export default AddProperty;
