import React, { ChangeEvent, ReactElement } from 'react';
import { Link as MUIlink } from '@mui/material';

import { Radio, Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';

import { CheckboxProps } from './types';

import './index.css';

const Checkbox = (props: CheckboxProps): ReactElement => {
  const {
    checked,
    label,
    className,
    name,
    onChange
  } = props;

  const classNames = `c-checkbox ${className}`;

  return (
    <div className={ classNames }>
      <MUICheckbox
        checked={checked}
        tabIndex={0}
        name={name}
        color="primary"
        disableRipple
        onChange={onChange}
      />
      <label>
        {label}
      </label>
    </div>
  )
}

export default Checkbox;