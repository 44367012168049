import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import format from 'format-string-by-pattern';

import Label from '../../atoms/Label';

import Loading from '../../atoms/Loading';
import Button, { ButtonSize } from '../../atoms/Button';
import FileUploader from '../../molecules/FileUploader';
import NoContent from '../../atoms/NoContent';

import { translate } from '../../utils/translate';
import { deleteClient, getClient, updateClient } from '../../services/actions';
import { Client, FileProps, IconList } from '../../static/types';
import { AuthContext } from '../../context';
import { isMobileScreen } from '../../utils/screen';
import { getIcon } from '../../utils/getIcon';
import { uploadFiles } from '../../services/actions/files';

import './index.css';

interface DetailedClientProps {
  id: string;
}

const DetailedClient = (props: DetailedClientProps): ReactElement => {
  const { id } = props;

  const [client, setClient] = useState<Partial<Client>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [files, setFiles] = useState<Array<FileProps>>([]);
  const [showSaveFile, setShowSaveFile] = useState<boolean>(false);
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = async () => {
    setLoading(true);

    await getClient(id)
      .then(client => {
        if (client) {
          setClient(client ?? {});
          setFiles(client?.files ?? []);
        }
      })
      .finally(() => setLoading(false));
  }

  const getFormattingByKey = (key: string): string => {
    const isMobile = isMobileScreen();

    return {
      ['firstName']: 'col-6',
      ['lastName']: 'col-6',
      ['label']: 'col-12',
      ['cpf']: 'col-6',
      ['rg']: 'col-6',
      ['phone']: isMobile ? 'col-6' : 'col-12',
      ['email']: 'col-12',
      ['street']: isMobile ? 'col-6' : 'col-4',
      ['district']: 'col-4',
      ['state']: isMobile ? 'col-2' : 'col-4',
      ['city']: isMobile ? 'col-6' : 'col-4',
      ['cep']: isMobile ? 'col-6' : 'col-8',
      ['content']: 'col-12',
    }[key] || '';
  }

  const normalizeData = (): Array<Record<string, string>> => {
    const keyOrder = ["label", "firstName", "lastName", "cpf", "rg", "phone", "email", "street", "district", "state", "city", "cep", "content"];

    const getValue = (key: string): string => {
      return {
        ['cpf']: format('999.999.999-99', client[key]),
        ['rg']: format('999.999.999.999', client[key]),
        ['phone']: format('(99) 9 9999-9999', client[key]),
        ['cep']: format('99999-999', client[key])
      }[key] || client[key] || '';
    }

    return keyOrder.map(key => ({
      customClassName: getFormattingByKey(key),
      label: translate(key),
      value: getValue(key)
    }));
  }

  const renderData = (): Array<ReactElement | null> | null => {
    const skipProperty = ['_id'];

    if (!client) {
      return null;
    }

    const handledData = normalizeData();

    return handledData.map(field => {
      if (skipProperty.includes(field.label)) {
        return null;
      }

      return (
        <div className={field.customClassName}>
          <Label title={field.label} value={field.value} />
        </div>
      )
    });
  }

  const handleDeleteClient = (): void => {
    deleteClient(id).then(() => navigate("/"));
  }
  
  const handleEditClient = (): void => {
    navigate(`/properties/edit?id=${id}`);
  }

  const renderRelatedProperty = (): ReactElement | ReactElement[] => {
    const Icon = getIcon(IconList.GPS);

    if (!Array.isArray(client.property) || !client.property.length) {
      return (
        <NoContent className="c-detailed-client__no-content" />
      )
    }

    return client.property.map(property => {
      const classNames = `c-detailed-client__property c-detailed-client__mode-${property.seilType}`;

      return (
        <div className={classNames}>
          <span className='c-detailed__title'>{property.title}</span>
          {!isMobileScreen() && (
            <span className='c-detailed-client__address'>
              <Icon />
              {`${property.street}, ${property.city} ${property.city} - ${property.state}`}
            </span>
          )}
          <Button
            value="ver"
            onClick={() => navigate(`/properties?id=${property._id}`)}
            size={ButtonSize.SMALL}
          />
        </div>
      );
    })
  }

  if (loading) {
    return (
      <div className='c-detailed-client'>
        <Loading />
      </div>
    );
  }

  const onFileUploadChange = (values: Array<FileProps>): void => {
    setFiles(values);
    setShowSaveFile(true);
  }

  const saveFiles = async () => {
    setLoadingFileUpload(true);

    const result = await uploadFiles(files);
    
    const data = {
      ...client,
      files: result
    }

    // @ts-ignore
    updateClient(data)
      .then((client) => {
        setFiles(client.files);
        toast.success("Arquivos atualizados com sucesso!");
      })
      .finally(() => {
        setShowSaveFile(false);
        setLoadingFileUpload(false)
      });
  }

  return (
    <div className='c-detailed-client'>
      <section>
        <label className='c-detailed-client__title'>{`${client.firstName} ${client.lastName || ''}`}</label>
        <div className='c-detailed-client__info'>
          {renderData()}
        </div>
      </section>
      <section>
        <label className='c-detailed-client__section-title'>Imóveis relacionados a este cliente</label>
        {renderRelatedProperty()}
        <label className='c-detailed-client__section-title'>Documentos</label>
        <div className='c-detailed-client__files'>
          <FileUploader
            values={client.files}
            onChange={onFileUploadChange}
          />
          {showSaveFile && <Button value='Salvar' onClick={saveFiles} loading={loadingFileUpload} />}
        </div>
      </section>
    </div>
  )
}

export default DetailedClient;

/*
      {state.isAuthenticated && (
        <div className='c-detailed-client__actions'>
          <Button
            className="c-detailed-property__delete"
            value="Deletar"
            color={ButtonColor.ERROR}
            onClick={handleDeleteClient}
          />
          <Button
            className="c-detailed-property__edit"
            value="Editar"
            onClick={handleEditClient}
          />
        </div>
      )}
*/
