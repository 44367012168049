import { InputType } from "../../../atoms/Input";

const data = [
  {
    fields: [
      {
        label: 'Username',
        name: 'username',
        type: 'text' as InputType,
        width: 12
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password' as InputType,
        width: 12
      }
    ]
  }
];

const initialValues = {
  username: '',
  password: ''
}

export {
  data,
  initialValues
}
