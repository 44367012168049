import { ReactElement } from 'react';
import DetailedClient from '../../organisms/DetailedClient';

interface DetailedClientPageProps {
  id: string;
}

const DetailedClientPage = (props: DetailedClientPageProps): ReactElement => {
  const { id } = props;

  return (
    <DetailedClient id={id} />
  )
}

export default DetailedClientPage;
