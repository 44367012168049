
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { SelectChangeEvent } from "@mui/material";

import Checkbox from "../../../../atoms/Checkbox";
import FormActions from "../FormActions";
import FormErrors from "../FormErrors";
import ImageUploader from "../../../../molecules/ImageUploader";
import Input, { InputSize, InputType } from '../../../../atoms/Input';
import Select from "../../../../atoms/Select";
import Title from "../Title";
import validator from './validator';

import { isMobileScreen } from "../../../../utils/screen";
import { ImageProps, Method } from "../../../../static/types";

import type { FieldProp, FormData, FormError, FormProps, InputProp } from './types';

import './index.css';

const LayoutForm = (props: FormProps) => {
  const {
    clearText,
    data,
    handleSubmit,
    initialValues = {},
    loading,
    method = Method,
    showClear,
    size = InputSize.MEDIUM,
    submitText,
    title,
  } = props;

  const [images, setImages] = useState<Array<ImageProps>>();

  useEffect(() => {
    if (initialValues.images) {
      setImages(initialValues.images as unknown as Array<ImageProps>);
    }
  }, [initialValues]);

  const handleSetImages = (event: Array<ImageProps>) => {
    setImages(event);
  }

  const renderFields = ({ values, handleChange, errors, setFieldValue }: InputProp): ReactElement[] => {
    return data.map((section: FormData) => (
      <div key={section.title} className="c-form-layout__section">
        {section.title && <Title value={section.title} />}
        
        {section.fields.map((field: FieldProp) => {
          const layoutClassName = `input__col-${field.width}`;
          const customClassName = `c-form-layout__input ${layoutClassName}`
          
          const formErrors = errors[field.name] as unknown as Array<string> ?? [];

          if (field.type === InputType.CHECKBOX) {
            return (
              <Checkbox
                key={field.name}
                checked={Boolean(values[field.name])}
                className={layoutClassName}
                label={field.label}
                name={field.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue(field.name, event.target.checked)}
              />
            )
          }

          if (field.type === InputType.SELECT) {
            return (
              <Select
                key={field.name}
                className={layoutClassName}
                label={field.label}
                options={field.options ?? []}
                endpoint={field.endpoint ?? ''}
                value={values[field.name]?.toString()}
                onChange={(event: SelectChangeEvent<string>) => setFieldValue(field.name, event.target.value)}
              />
            )
          }

          if (field.type === InputType.IMAGE) {
            return (
              <ImageUploader
                name={field.name}
                onChange={handleSetImages}
                values={values[field.name] as Array<ImageProps>}
              />
            );
          }

          return (
            <div key={field.name} className={customClassName}>
              <Input
                key={field.name}
                label={field.label}
                name={field.name}
                value={values[field.name]}
                onChange={handleChange}
                size={isMobileScreen() ? InputSize.SMALL : size}
                type={field.type}
                max={field.max}
              />
              <FormErrors errors={formErrors} />
            </div>
          );
        })}
      </div>
    ));
  }

  const validateForm = (event: Record<string, string | boolean | ImageProps[]>): FormError | void => {
    const errors = validator(event, data);    
    return errors;
  }

  const onSubmit = (values: Record<string, string | boolean | ImageProps[]>) => {
    values.images = images ?? [];

    const errors = validator(values, data);
    const hasErrors = Object.keys(errors).some(key => errors[key].length);

    if (hasErrors) {
      return;
    }

    handleSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <Form>
            {title && <label className="c-form-layout__title">{title}</label>}
            <div className="c-form-layout__sections">
              {renderFields({ values, handleChange, errors, setFieldValue })}
            </div>

            <FormActions
              className="c-form__form-actions-margin"
              clearText={clearText}
              handleReset={() => document.location.reload()}
              loading={loading}
              showClear={false}
              onSubmit={() => onSubmit(values)}
              // showClear={showClear}
              submitText={submitText} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default LayoutForm;
