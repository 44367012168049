import React, { ReactElement, Fragment, ChangeEvent, useState, useEffect } from 'react';

import Checkbox from '../../atoms/Checkbox';

import { IconList, FileProps } from '../../static/types';
import { FileUploaderProps, FilePreviewProps } from './types';

import './index.css';
import { getIcon } from '../../utils/getIcon';
import NoContent from '../../atoms/NoContent';

const FilePreview = (props: FilePreviewProps) => {
  const {
    file,
    id,
    name,
    removeFile,
  } = props;

  const Logo = getIcon(IconList.PDF);

  return (
    <div className="c-file-uploader__file-preview">
      <a href={file.toString()}>
        <Logo />
        <span className='c-file-uploader__file-name'>{name}</span>
      </a>
      <div
        className='c-file-uploader__file-preview__delete'
        onClick={() => removeFile(id)}
      />
    </div>
  )
}

const FileUploader = (props: FileUploaderProps): ReactElement => {
  const {
    onChange,
    values = []
  } = props;

  const [files, setFiles] = useState<Array<FileProps>>([]);

  useEffect(() => {
    if (!files.length && values.length) {
      setFiles(values);
    }
  }, []);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      const newFile: FileProps = {
        file,
        id: new Date().getTime(),
        name: file.name,
      }

      const fileData = [ ...files, newFile];

      setFiles(fileData);
      onChange(fileData);
    }
  }

  const removeFile = (id: string | number): void => {
    const newFilesData = files.filter(file => file.id !== id);

    setFiles(newFilesData);
    onChange(newFilesData);
  }

  const renderFiles = (): ReactElement[] | ReactElement | null => {

    if (!files.length) {
      return (
        <NoContent className="c-detailed-client__no-content" />
      )
    }
    return files.map((file: FileProps) => (
      <FilePreview
        id={file.id}
        name={file.name}
        file={file.file}
        removeFile={removeFile}
      />
    ));
  }

  return (
    <div className='c-file-uploader'>
      {renderFiles()}
      <input
        type="file"
        id="file-uploader"
        name="filename"
        hidden
        onChange={onFileChange}
      />
      <label
        htmlFor="file-uploader"
        className='c-file-uploader__upload-btn' 
      />
    </div>
  );
}

export default FileUploader;
