import React, { ReactElement } from 'react';

import { BadgeProps } from './types';
import { translate } from '../../utils/translate';

import { SeilType } from '../../static/types';

import './index.css';

const Badge = (props: BadgeProps): ReactElement => {
  const { type } = props;

  const getClassName = (): string => {

    const customClass = {
      [SeilType.RENT]: 'c-badge-rent',
      [SeilType.SELL]: 'c-badge-sell',
    }[type];

    return `c-badge ${ customClass }`;
  }

  return (
    <div className={getClassName()}>
      {translate(type)}
    </div>
  )
}

export default Badge;
