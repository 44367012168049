import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { TableCell } from '@mui/material';

import Pagination from '../../atoms/Pagination';
import Table from '../../atoms/Table';

import { deleteClient, getClients, getClientsMetadata } from '../../services/actions';
import { Client, IconList } from '../../static/types';
import { getIcon } from '../../utils/getIcon';

import { headerColumns } from './table-data';

import './index.css';
import { handleBigText } from '../../utils/normalize';
import Button, { ButtonVariant } from '../../atoms/Button';
import { toast } from 'react-toast';

interface Metadata {
  total: number;
}

const ClientsTable = (): ReactElement => {
  const [data, setData] = useState<Array<Client> | undefined>(undefined);
  const [metadata, setMetadata] = useState<Metadata>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingBtnLoading, setDeletingBtnLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, [page, limit]);
  
  useEffect(() => {
    getMetadata();
  }, []);

  const fetchClients = () => {
    setLoading(true);
    
    getClients({ page, limit })
      .then(response => {
        setData(response);
        setLoading(false);
      });
  }

  const getMetadata = () => {
    getClientsMetadata()
      .then(response => {
        setMetadata({ total: Math.ceil(response.total / limit) })}
      );
  }

  const Icon = getIcon(IconList.PLUS);

  const renderTableRow = (row: Client): ReactElement => {
    return (
      <>
        <TableCell onClick={() => handleDetailClick(row._id)}>{row.firstName}</TableCell>
        <TableCell>{row.street} - {row.state}</TableCell>
        <TableCell>{row.label}</TableCell>
        <TableCell style={{ minWidth: '5rem' }}>{moment(row.createdAt).format('DD MMM YYYY')}</TableCell>
        <TableCell style={{ minWidth: '7rem' }}>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{handleBigText(row.content ?? '')}</TableCell>
      </>
    )
  }

  const handleDeleteClick = (_id: string): void => {
    setDeletingBtnLoading(true);
    
    deleteClient(_id)
      .then(() => {
        fetchClients();
        toast.success("Cliente deletado com sucesso!");
      })
      .finally(() => {
        setDeletingBtnLoading(false);
      });
  }

  const handleDetailClick = (_id: string): void => {
    navigate(`/clients?id=${_id}`);
  }

  const handleSeeMoreClick = (_id: string): void => {
    navigate(`/clients/edit?id=${_id}`);
  }

  const renderPopoverContent = (_id: string): ReactElement => {
    return (
      <div className='c-client-table__popover-options'>
        <Button variant={ButtonVariant.TEXT} value="Ver mais" onClick={() => handleDetailClick(_id)} />
        <Button variant={ButtonVariant.TEXT} value="Editar" onClick={() => handleSeeMoreClick(_id)} />
        <Button loading={deletingBtnLoading} variant={ButtonVariant.TEXT} value="Deletar" onClick={() => handleDeleteClick(_id)} />
      </div>
    )
  }

  return (
    <div className='c-client-table__table-wrapper'>
      <div className='c-client-table__content'>
        <Table
          data={data}
          headers={headerColumns}
          id='clients-table-id'
          loading={loading}
          popoverContent={renderPopoverContent}
          renderTableRow={renderTableRow}
        />
        <Icon className="c-client-table__add" onClick={() => navigate('/clients/add')} />
      </div>
      <Pagination
        count={metadata?.total || 1}
        page={page}
        onChange={(_event, page) => setPage(page)}
      />
    </div>
  );
}

export default ClientsTable;
