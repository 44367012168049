import { ReactElement } from 'react';

import './index.css';

interface TitleProps {
  value: string,
}

const Title = (props: TitleProps): ReactElement => {
  const {
    value
  } = props;

  return (
    <span className="c-form-layout__section-subtitle">{value}</span>
  )
}

export default Title;
