const isMobileDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const isMobileScreen = (): boolean => {
  return window.screen.availWidth < 600;
}

const isSmallDesksize = (): boolean => {
  return window.screen.availWidth < 1366;
}

export {
  isMobileDevice,
  isMobileScreen,
  isSmallDesksize
}
