import { ReactElement, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import DetailedProperty from '../../organisms/DetailedProperty';

import PropertyListLayout from '../../organisms/PropertyListLayout';

const Properties = (): ReactElement => {
  const { search } = useLocation();

  const [propertyId, setPropertyId] = useState<string | null>(null);

  const query = new URLSearchParams(search);

  useEffect(() => {
    const id = query.get("id");
    setPropertyId(id);
  }, [query]);

  return propertyId ? <DetailedProperty id={propertyId} /> : <PropertyListLayout />;
}

export default Properties;
