import { ReactElement } from 'react';
import format from 'format-string-by-pattern';

import Button from '../../atoms/Button';
import Href from '../../atoms/Href';
import { ContactSellerProps } from './types';
import { getIcon } from '../../utils/getIcon';
import { getCurrency } from '../../utils/number';
import { IconList } from '../../static/types';
import { isMobileScreen } from '../../utils/screen';

import './index.css';

const ContactSeller = (props: ContactSellerProps): ReactElement => {
  const { id, condominium, price, tax } = props;
  
  const SERVER_URL = process.env.REACT_APP_PROD_APP_URL;

  const renderSocialMedia = (): ReactElement => {
    const WhatsIcon = getIcon(IconList.WHATSAPP);
    const InstagramIcon = getIcon(IconList.INSTAGRAM);
    const EmailIcon = getIcon(IconList.EMAIL);

    const bodyMessage = `Olá! \n\nTenho interesse neste imóvel: ${SERVER_URL}/properties?id=${id}`;
    
    const urlWeb = `https://wa.me/555192440636?text=${encodeURI(`Olá! \n\nTenho interesse neste imóvel: ${SERVER_URL}/properties?id=${id}`)}`;
    const urlMobile = `whatsapp://send?text=${bodyMessage}&phone=555192440636`;

    return (
      <div className="c-contact-seller__social-media">
        <Href
          value={<EmailIcon />}
          href={`mailto:batistascorretor@gmail.com?&bcc=batistascorretor@gmail.com&subject=Site Batista Corretor de Imóveis - Interesse por imóvel&body=${bodyMessage}`}
        />
        <Href
          value={<InstagramIcon />}
          href='https://www.instagram.com/batista.corretordeimoveis/'
          target="_blank"
        />
        <Href
          value={<WhatsIcon />}
          href={
            isMobileScreen()
              ? urlMobile
              : urlWeb
          }
          target="_blank"
        />
      </div>
    )
  }

  const getTotalPrice = (): string => {
    return getCurrency(Number(condominium ?? 0) + Number(price ?? 0) + Number(tax ?? 0));
  }

  return (
    <div className='c-contact-seller'>
      <label className='c-contact-seller__title'>Se interessou por este imóvel?</label>

      <div className='c-contact-seller__prices'>
        <div>
          <label>Value</label>
          <label>{price ? getCurrency(price) : 'Não informado'}</label>
        </div>

        <div>
          <label>IPTU</label>
          <label>{tax ? getCurrency(tax) : 'Não informado'}</label>
        </div>
        <div>
          <label>Condomínio</label>
          <label>{condominium ? getCurrency(condominium) : 'Não informado'}</label>
        </div>


        <hr className='c-contact-seller__hr' />
        <div className='c-contact-seller__total'><label>{getTotalPrice()}</label></div>
      </div>

      <div className='c-contact-seller__contact'>
        <label>Converse com o corretor:</label>
        <Button value="Tenho interesse!" />
        <label className='c-contact-seller__contact-separator'>ou</label>

        {renderSocialMedia()}
      </div>
    </div>
  )
};

export default ContactSeller;
