import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FormClient from '../../organisms/Form/FormClient';
import Loading from '../../atoms/Loading';
import NoContent from '../../atoms/NoContent';

import { Client, Method } from '../../static/types';
import { getClient } from '../../services/actions';

const EditClient = (): ReactElement => {
  const { search } = useLocation();

  const [client, setClient] = useState<Client | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const query = new URLSearchParams(search);

  useEffect(() => {
    const id = query.get("id");
    
    if (!id || client) return;
    
    setLoading(true);

    getClient(id)
      .then(client => setClient(client))
      .finally(() => setLoading(false));
  }, [client, query, search]);

  const customStyle = {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 20rem)',
    justifyContent: 'center',
  }

  if (loading) {
    return (
      <div className='c-edit-client' style={customStyle}>
        <Loading />
      </div>
    );
  }

  return client
    ? <FormClient defaultValues={client} method={Method.PATCH} />
    : <NoContent />;
}

export default EditClient;
