import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";

import { DetailedPropertyProps } from './types';

import Button, { ButtonColor } from '../../atoms/Button';
import BadgeInfoList from '../../molecules/BadgeInfoList';
import ContactSeller from '../../molecules/ContactSeller';
import Loading from '../../atoms/Loading';

import { getIcon } from '../../utils/getIcon';
import { IconList, Property } from '../../static/types';
import { deleteProperty, getProperty } from '../../services/actions';
import { AuthContext } from '../../context';

import './index.css';

const DetailedProperty = (props: DetailedPropertyProps): ReactElement => {
  const { id } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [property, setProperty] = useState<Partial<Property>>({});

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  const Icon = getIcon(IconList.GPS);

  useEffect(() => {
    fetchProperty();
  }, []);

  const fetchProperty = async () => {
    setLoading(true);
    getProperty(id)
      .then(property => setProperty(property ?? {}))
      .finally(() => setLoading(false));
  }

  const handleEditProperty = (): void => {
    navigate(`/properties/edit?id=${id}`);
  }

  const handleDeleteProperty = (): void => {
    deleteProperty(id)
      .then(() => navigate("/"));
  }

  const getImages = () => {
    const images = property?.images?.map(image => image.file);

    return images?.map(image => <img src={image.toString()} />);
  }

  if (loading) {
    return (
      <div className="c-detailed-property">
        <Loading />
      </div>
    );
  }

  return (
    <div className="c-detailed-property">
      <div className='c-detailed-property__property-info'>
        <div className='c-detailed-property__property-info__image-box'>
          <Carousel autoPlay className="c-detailed-property__carousel">
            {getImages()}
          </Carousel>
        </div>
        <div className='c-detailed-property__content'>
          <label className='c-detailed-property__content-title'>{property.title}</label>
          <label className='c-detailed-property__content-description'>{property.description}</label>
          <label className='c-detailed-property__content-address'><Icon />{`${property.street}, ${property.district} - ${property.city} - ${property.state}`}</label>

          <BadgeInfoList data={property} showLabel />
        </div>
      </div>
      <div className='c-detailed-property__contact'>
        <ContactSeller
          id={id}
          condominium={property.condominium}
          price={property.price}
          tax={property.iptu}
        />
        {state.isAuthenticated && (
          <div className='c-admin-buttons'>
            <Button
              className="c-detailed-property__delete"
              value="Deletar"
              color={ButtonColor.ERROR}
              onClick={handleDeleteProperty}
            />
            <Button
              className="c-detailed-property__edit"
              value="Editar"
              onClick={handleEditProperty}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DetailedProperty;
