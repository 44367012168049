import { ChangeEvent } from "react";

enum PaginationSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
};

interface PaginationProp {
  disabled?: boolean;
  count: number;
  onChange?: (event: ChangeEvent<unknown>, page: number) => void;
  size?: PaginationSize;
  page: number;
}

export {
  PaginationSize
}

export type {
  PaginationProp
}
