import AWS from 'aws-sdk';
import { ManagedUpload } from "aws-sdk/clients/s3";

import { FileProps } from "../../static/types"

interface FilesUploaded {
  files?: Array<FileProps>;
}

AWS.config.update({ region: 'us-west-2' });

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: 'us-west-2',
  apiVersion: '2006-03-01'
});

const uploadFiles = async (data: Array<FileProps>): Promise<Array<FileProps>> => {
  if (!data || !data.length) {
    return [];
  }

  // filter files not uploaded yet
  const fileData = data.filter(file => typeof file.file === 'object');
  const filesAlreadyUploaded = data.filter(file => typeof file.file === 'string');

  const files: Array<FileProps> = filesAlreadyUploaded;

  const promises = fileData.map(async file => {
    const uploadParams = {
      Bucket: process.env.REACT_APP_S3_NAME ?? 'immobilie',
      Key: file.name,
      Body: file.file,
    }

    try {
      const response: ManagedUpload.SendData = await s3.upload(uploadParams).promise();

      files.push({ ...file, file: response.Location });
    } catch (error) {
      console.error(error);
    }
  })

  await Promise.all(promises);

  return files;
}

export {
  uploadFiles
}