import React, { ReactElement, useEffect, useState } from 'react';
import {
  useLocation
} from "react-router-dom";

import ClientsTable from '../../molecules/ClientsTable';
import DetailedClient from './DetailedClient';

const Client = (): ReactElement => {
  const { search } = useLocation();

  const [userId, setUserId] = useState<string | null>(null);

  const query = new URLSearchParams(search);

  useEffect(() => {
    const id = query.get("id");
    setUserId(id);
  }, [query]);

  return userId ? <DetailedClient id={userId} /> : <ClientsTable />;
}

export default Client;
