import { ReactElement } from "react";

import { InfoBadgeProps } from "../../atoms/InfoBadge";
import { Property, SeilType } from "../../static/types";

enum PropertyCardMode {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface PropertyCardProps extends Property {
  isBox?: boolean;
  mode?: PropertyCardMode,
  short?: boolean;
  isPropertyList?: boolean;
  showImage?: boolean;
}

export {
  PropertyCardMode
}

export type {
  PropertyCardProps
}
