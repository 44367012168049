import { ReactElement } from "react"

enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

enum ButtonColor {
  INHERIT = 'inherit',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
};

enum ButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

interface ButtonProp {
  color?: ButtonColor;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  value: string | number;
  variant?: ButtonVariant;
  type?: ButtonType;
  endIcon?: ReactElement;
  startIcon?: ReactElement;
}

export {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
}

export type {
  ButtonProp
}
