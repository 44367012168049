import { Fragment, ReactElement, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';

import Button, { ButtonType, ButtonVariant } from '../../atoms/Button';
import ComboBox, { ComboBoxChange, ComboBoxOptions } from '../../atoms/ComboBox';
import Input, { InputSize } from '../../atoms/Input';
import PriceSlider from '../../atoms/PriceSlider';

import { getKeys, getValues } from '../../atoms/ComboBox/utils';
import { isMobileScreen } from '../../utils/screen';
import { FilterBoxProps } from './types';
import { IconList, ImmobilieType, PropertyPriceMetadata } from '../../static/types';
import { getPropertyPriceMetadata } from '../../services/actions';
import { getIcon } from '../../utils/getIcon';

import './index.css';

const FilterBox = (props: FilterBoxProps): ReactElement => {
  const {
    defaultValues = {},
    isBox,
    onSubmit
  } = props;

  const navigate = useNavigate();

  const [price, setPrice] = useState<Array<number>>([1250, 300000]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [priceMetadata, setMetadata] = useState<PropertyPriceMetadata>({ min: 0, max: 100000 });

  useEffect(() => {
    getPriceMetadata();
  }, []);

  const getPriceMetadata = async () => {
    await getPropertyPriceMetadata()
      .then(metadata => {
        if (metadata) {
          setMetadata(metadata);
          setPrice([metadata.min, metadata.max])
        }
      });
  }

  const handleSubmit = (formValues?: Record<string, string>): void => {
    const values: unknown = {
      ...formValues,
      price,
    }

    onSubmit(values);
  }

  const handlePriceChange = (value: Array<number>): void => {
    setPrice(value);
  }

  const getFilterContent = (): ReactElement => {
    const Close = getIcon(IconList.X);

    return (
      <Formik
        initialValues={{}}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <div className="c-filter-box__form">
              <div className="c-filter-box__header">
                <h3 className="c-filter-box__title">Encontre o imóvel perfeito</h3>
                {isMobileScreen() && !isBox ? <Close onClick={() => setShowFilters(false)} /> : null}
              </div>
              <div className="c-filter-box__fields">
                <Input
                  label="Cidade, bairro, localidade"
                  name="location"
                  onChange={handleChange}
                  className='input__col-12'
                  value={values["location"]}
                  size={InputSize.SMALL}
                />
                {!(isBox && isMobileScreen()) && (
                  <Input
                    label="Quartos"
                    name="room"
                    onChange={handleChange}
                    value={values["room"]}
                    className='input__col-6'
                    size={InputSize.SMALL}
                  />
                )}
                {!isBox && (
                  <Input
                    label="Vagas"
                    name="garage"
                    onChange={handleChange}
                    value={values["garage"]}
                    className='input__col-6'
                    size={InputSize.SMALL}
                  />
                )}
                {!(isBox && isMobileScreen()) && (
                  <Input
                    label="Banheiros"
                    name="bathroom"
                    onChange={handleChange}
                    value={values["bathroom"]}
                    className='input__col-6'
                    size={InputSize.SMALL}
                  />
                )}
                {!isBox && (
                  <Input
                    label="m²"
                    value={values["footage"]}
                    name="footage"
                    onChange={handleChange}
                    className='input__col-6'
                    size={InputSize.SMALL}
                  />
                )}
              </div>
              {!(isBox && isMobileScreen()) && (
                <span className="c-filter-box__advice">* Valor mínimo. Exemplo: mínimo 2 banheiros.</span>
              )}

              {!isBox && (
                <div className="c-filter-box__combo-box">
                  <ComboBox
                    name="acceptPet"
                    onChange={(event: ComboBoxChange) => setFieldValue("acceptPet", getValues(event.value as ComboBoxOptions))}
                    label='Aceita animais'
                    value={getKeys(values['acceptPet'])}
                  />
                  <ComboBox
                    name={ImmobilieType.HOUSE}
                    onChange={(event: ComboBoxChange) => setFieldValue(ImmobilieType.HOUSE, getValues(event.value as ComboBoxOptions))}
                    label='Casa'
                    value={getKeys(values[ImmobilieType.HOUSE])}
                  />
                  <ComboBox
                    name={ImmobilieType.APARTMENT}
                    onChange={(event: ComboBoxChange) => setFieldValue(ImmobilieType.APARTMENT, getValues(event.value as ComboBoxOptions))}
                    label='Apartamento'
                    value={getKeys(values[ImmobilieType.APARTMENT])}
                  />
                  <ComboBox
                    name={ImmobilieType.GROUND}
                    onChange={(event: ComboBoxChange) => setFieldValue(ImmobilieType.GROUND, getValues(event.value as ComboBoxOptions))}
                    label='Terreno'
                    value={getKeys(values[ImmobilieType.GROUND])}
                  />
                  <ComboBox
                    name={ImmobilieType.KITCHENETTE}
                    onChange={(event: ComboBoxChange) => setFieldValue(ImmobilieType.KITCHENETTE, getValues(event.value as ComboBoxOptions))}
                    label='Quitinete'
                    value={getKeys(values[ImmobilieType.KITCHENETTE])}
                  />
                  <ComboBox
                    name={ImmobilieType.BATCH}
                    onChange={(event: ComboBoxChange) => setFieldValue(ImmobilieType.BATCH, getValues(event.value as ComboBoxOptions))}
                    label='Lote'
                    value={getKeys(values[ImmobilieType.BATCH])}
                  />
                  <ComboBox
                    name="rent"
                    onChange={(event: ComboBoxChange) => setFieldValue("rent", getValues(event.value as ComboBoxOptions))}
                    label='Aluguel'
                    value={getKeys(values['rent'])}
                  />
                  <ComboBox
                    name="sell"
                    onChange={(event: ComboBoxChange) => setFieldValue("sell", getValues(event.value as ComboBoxOptions))}
                    label='Venda'
                    value={getKeys(values['sell'])}
                  />
                </div>
              )}
              <div className="c-filter-box__slider-price">
                <span className="c-filter-box__slider-title">Faixa de preço:</span>
                <PriceSlider
                  min={priceMetadata.min}
                  max={priceMetadata.max}
                  value={price}
                  onChange={handlePriceChange}
                />
              </div>
              <div className="c-filter-box__button">
                <Button
                  value="encontrar"
                  onClick={() => {
                    if (isBox) {
                      navigate('/properties');
                    } else {
                      handleSubmit(values);
                      setShowFilters(false);
                    }        
                  }}
                  type={ButtonType.SUBMIT}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <div className='c-filter-box'>
      {isMobileScreen() && !isBox
        ? (
          <Fragment>
            <Button
              value="Filtros"
              className="c-filter-box__show-filter-btn"
              onClick={() => setShowFilters(true)}
              variant={ButtonVariant.OUTLINED}
              type={ButtonType.SUBMIT}
            />
            <Modal
              open={showFilters}
              onClose={() => setShowFilters(false)}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              component='div'
            >
              <div className='c-filter-box__modal-content'>
                {getFilterContent()}
              </div>
            </Modal>
          </Fragment>
        ) :
          getFilterContent()
        }
    </div>
  )
};

export default FilterBox;
