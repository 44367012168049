import { InputType } from "../../../atoms/Input";
import { ImmobilieType, SeilType } from "../../../static/types";

const data = [
  {
    title: "Informações de localização",
    fields: [
      {
        label: 'Rua, avenia, travessa',
        name: 'street',
        type: InputType.TEXT,
        min: 5,
        width: 9
      },
      {
        label: 'Bairro',
        name: 'district',
        type: InputType.TEXT,
        min: 2,
        width: 3
      },
      {
        label: 'Cidade',
        name: 'city',
        type: InputType.TEXT,
        min: 5,
        width: 4
      },
      {
        label: 'Estado',
        min: 2,
        name: 'state',
        type: InputType.TEXT,
        width: 4
      },
      {
        label: 'CEP',
        name: 'cep',
        type: InputType.TEXT,
        width: 4
      }
    ]
  },
  {
    title: "Informações financeiras",
    fields: [
      {
        label: 'Valor',
        name: 'price',
        type: InputType.PRICE,
        min: 0,
        width: 4
      },
      {
        label: 'IPTU',
        name: 'iptu',
        type: InputType.PRICE,
        width: 4
      },
      {
        label: 'Condomínio',
        name: 'condominium',
        type: InputType.PRICE,
        width: 4
      },
      {
        label: 'Tipo de negócio',
        name: 'seilType',
        type: InputType.SELECT,
        options: [
          {
            label: 'Venda',
            value: SeilType.SELL
          },
          {
            label: 'Aluguel',
            value: SeilType.RENT
          }
        ],
        width: 4
      },
      {
        label: 'Água incluso',
        name: 'hasWater',               // In the amount value of the condominium
        type: InputType.CHECKBOX,
        width: 4
      },
      {
        label: 'Luz inclusa',
        name: 'hasEletricity',          // In the amount value of the condominium
        type: InputType.CHECKBOX,
        width: 4
      } 
    ]
  },
  {
    title: "Informações do imóvel",
    fields: [
      {
        label: 'Títilo',
        name: 'title',
        type: InputType.TEXT,
        min: 5,
        width: 12
      },
      {
        label: 'Descrição',
        name: 'description',
        type: InputType.TEXTAREA,
        min: 5,
        max: 500,
        width: 12
      },
      {
        label: 'Quartos',
        name: 'room',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Banheiros',
        name: 'bathroom',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Suítes',
        name: 'suite',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Vagas garagem',
        name: 'garage',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Tamanho em m²',
        name: 'footage',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Minutos até o metrô',
        name: 'minSubway',
        type: InputType.NUMBER,
        min: 0,
        width: 4
      },
      {
        label: 'Tipo de imóvel',
        name: 'immobilieType',
        options: [
          {
            label: 'Casa',
            value: ImmobilieType.HOUSE
          },
          {
            label: 'Apartamento',
            value: ImmobilieType.APARTMENT
          },
          {
            label: 'Terreno',
            value: ImmobilieType.GROUND
          },
          {
            label: 'Quitinete',
            value: ImmobilieType.KITCHENETTE
          },
          {
            label: 'Lote',
            value: ImmobilieType.BATCH
          }
        ],
        type: InputType.SELECT,
        width: 4
      },
      {
        label: 'Aceita pet',
        name: 'acceptPet',
        type: InputType.CHECKBOX,
        width: 4
      },
      {
        label: 'Possui piscina',
        name: 'hasPool',
        type: InputType.CHECKBOX,
        width: 4
      }
    ]
  },
  {
    title: "Informações do proprietário",
    fields: [
      {
        label: 'Cliente',
        name: 'client',
        type: InputType.SELECT,
        endpoint: 'client',
        width: 12
      }
    ]
  },
  {
    title: "Imagens",
    fields: [
      {
        label: 'Imagens',
        name: 'images',
        type: InputType.IMAGE,
        width: 12
      }
    ]
  }
];

const initialValues = {};

export {
  data,
  initialValues
}
