import React, { ReactElement, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Home } from './pages/Home';
import { Clients, EditClient, AddClient } from './pages/Client';
import { AddProperty, EditProperty, Properties } from './pages/Property';
import { Login } from './pages/Login';
import { AuthContext } from './context';

import Layout from './templates/Layout';

const AppRoutes = (): ReactElement => {
  const { state: { isAuthenticated } } = useContext(AuthContext);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />

        {isAuthenticated && 
          <Route path="clients">
            <Route index element={<Clients />} />

            <Route path="add" element={<AddClient />} />

            <Route path="edit" element={<EditClient />} />
          </Route>
        }
        
        <Route path="properties">
          <Route index element={<Properties />} />

          {isAuthenticated && (
            <>
              <Route path="add" element={<AddProperty />} />
              <Route path="edit" element={<EditProperty />} />
            </>
            )
          }
        </Route>

        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <Navigate to='/' />
          }
        />
      </Routes>
    </Layout>
  );
}

export default AppRoutes;
