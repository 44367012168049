import { Fragment, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import Badge from '../../atoms/Badge';

import Button, { ButtonSize } from '../../atoms/Button';
import InfoBadge from '../../atoms/InfoBadge';

import { getIcon } from '../../utils/getIcon';
import { getCurrency } from '../../utils/number';

import { isMobileScreen, isSmallDesksize } from '../../utils/screen';

import { PropertyCardProps, PropertyCardMode } from './types';

import './index.css';
import { IconList, Informations } from '../../static/types';
import BadgeInfoList from '../BadgeInfoList';

const PropertyCard = (props: PropertyCardProps): ReactElement => {
  const {
    _id,
    bannerImage,
    city,
    condominium,
    description,
    district,
    iptu,
    isBox,
    isPropertyList,
    mode = PropertyCardMode.HORIZONTAL,
    price,
    seilType,
    short,
    showImage = true,
    state,
    street,
    title,
  } = props;

  const navigate = useNavigate();

  const onClick = (index: string): void => {
    navigate(`/properties?id=${index}`);
  }

  const getTotalPrice = (): string => {
    return getCurrency(Number(price) + Number(iptu || 0) + Number(condominium || 0));
  }

  const isVertical = mode === PropertyCardMode.VERTICAL;

  const classNames = `c-card-property ${isVertical ? 'c-card__vertical' : ''}`;
  const buttonTitle = isVertical ? 'conheça essa oferta' : 'ver mais';
  const Icon = getIcon(IconList.LEFT_ARROW_LIGHT);

  return (
    <div className={classNames} onClick={() => isMobileScreen() && _id && onClick(_id)}>
      {showImage && <img src={String(bannerImage?.file)} />}

      <div className='c-card-property__content'>
        {isVertical && <BadgeInfoList isPropertyList={isPropertyList} data={props} short={short} />}

        <label className='c-card-property__title'>{title}</label>

        {!(short && mode === PropertyCardMode.HORIZONTAL) && <label className='c-card-property__description'>{description}</label>}

        <div className='c-card-property__address'>
          {short
            ? <InfoBadge icon={IconList.GPS} value={`${district}, ${city} ${state && `- ${state}`}`} />
            : <InfoBadge icon={IconList.GPS} value={`${street}, ${district} - ${city} ${state && `- ${state}`}`} />
          }
        </div>

        {!isVertical && <BadgeInfoList isPropertyList={isPropertyList} data={props} short={short} />}

        {isVertical || isMobileScreen() && (
          <div className='c-card-property__price'>
            À partir de
            <label>
              {getCurrency(price)}
            </label>
          </div>
        )}

        {isBox && (
          <Fragment>
            {iptu ?
              <div className='c-card-property__price'>
                IPTU
                <label>
                  {getCurrency(iptu)}
                </label>
              </div>
              : null
            }
            {condominium ?
              <div className='c-card-property__price'>
                Condomínio
                <label>
                  {getCurrency(condominium)}
                </label>
              </div>
              : null
            }
            <div className='c-card-property__price'>
              Valor
              <label>
                {getCurrency(price)}
              </label>
            </div>
            <hr className='c-card-property__line' />
            <div className='c-card-property__price'>
              Total
              <label>
                {getTotalPrice()}
              </label>
            </div>
          </Fragment>
        )}
      </div>

      <div className='c-card-property__button-section'>
        {seilType && <Badge type={seilType} />}
        <Button
          value={buttonTitle}
          endIcon={isVertical ? <Icon /> : undefined}
          onClick={() => _id && onClick(_id)}
          size={isSmallDesksize() ? ButtonSize.SMALL : ButtonSize.MEDIUM}
        />
      </div>
    </div>
  );
}

export default PropertyCard;
