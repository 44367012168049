import { ReactElement } from 'react';

import './index.css';

interface FormErrorsProps {
  errors: Array<string>;
}

const FormErrors = (props: FormErrorsProps): ReactElement | null => {
  const { errors } = props;

  if (!errors.length) {
    return null;
  }

  return (
    <div className='c-form-errors__wrapper'>
      {errors.map(error => (
        <span className='c-form-errors__error' key={error}>{error}</span>
      ))}
    </div>
  );
}

export default FormErrors;
