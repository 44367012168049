import axios, { AxiosError } from 'axios';

import { Property, PropertyPriceMetadata } from '../../static/types';
import { uploadImages } from './images';
import { feedback } from './userFeedback';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const BASE_URL = 'property';

const URI = `${ SERVER_URL }/${BASE_URL}`;

interface Query {
  page?: number;
  limit?: number;
}

const getProperty =  async (id: string): Promise<Property | undefined> => {
  try {
    const response = await axios.get(`${ URI }/${id}`);
    return response.data as Property;
  } catch (error) {
    console.error(error);
  }
}

const getProperties = async (query?: Query): Promise<Array<Property> | undefined> => {
  try {
    const response = await axios.get(URI, {
      params: {
        ...query
      }
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const getPropertyPriceMetadata = async (): Promise<PropertyPriceMetadata | undefined> => {
  try {
    const response = await axios.get(`${ URI }/price-metadata`);
    return response.data as PropertyPriceMetadata;
  } catch (error) {
    console.error(error);
  }
}

const createProperty =  async (data: Property) => {
  try {
    const { bannerImage, images = [] } = await uploadImages(data.images ?? []);

    data.images = images;
    data.price = Number(data.price);
    data.iptu = Number(data.iptu);
    data.condominium = Number(data.condominium);
    data.bannerImage = bannerImage;

    const response = await axios.post(URI, data);
    
    feedback(response);

    return response;
  } catch (error: any) {
    const err = (error as AxiosError).response;
    
    if (err) {
      feedback(err);
    }
  }
}

const updateProperty =  async (data: Property) => {
  try {
    const { _id = '' } = data;
    
    const { bannerImage, images = [] } = await uploadImages(data.images ?? []);
    
    data.images = images;
    data.price = Number(data.price);
    data.bannerImage = bannerImage;

    const response = await axios.patch(`${ URI }/${_id}`, data);

    feedback(response);

    return response;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

const deleteProperty =  async (_id: string) => {
  try {
    const response = await axios.delete(`${ URI }/${_id}`);

    feedback(response);

    return response;
  } catch (error) {
    const err = (error as AxiosError).response;

    if (err) {
      feedback(err);
    }
  }
}

export {
  createProperty,
  deleteProperty,
  getProperties,
  getProperty,
  getPropertyPriceMetadata,
  updateProperty
}
