import { ReactElement } from 'react';

import FormClient from '../../organisms/Form/FormClient';

const DetailedClient = (): ReactElement => {

  return (
    <FormClient customCallback={() => 'document.location.reload()'} />
  )
}

export default DetailedClient;
