enum IconList {
  BATHROOM = 'bathroom',
  BEDROOM = 'bedroom',
  CAR = 'car',
  EMAIL = 'email',
  FOOTAGE = 'footage',
  GARAGE = 'garage',
  GPS = 'gps',
  INSTAGRAM = 'instagram',
  LEFT_ARROW_LIGHT = 'left-arrow-light',
  LEFT_ARROW_THIN = 'left-arrow-thin',
  LOADING = 'loading',
  MIN_TO_METRO = 'minSubway',
  PDF = 'pdf',
  PET = 'pet',
  PLUS = 'plus',
  ROOM = 'room',
  RULER = 'ruler',
  SUITE = 'suite',
  THREE_DOTS = 'three-dots',
  WHATSAPP = 'whatsapp',
  X = 'x'
}

enum SeilType {
  SELL = 'sell',
  RENT = 'rent'
}

enum ImmobilieType {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  GROUND = 'ground',
  KITCHENETTE = 'kitchenette',
  BATCH = 'batch',
}

enum Method {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export {
  IconList,
  SeilType,
  ImmobilieType,
  Method
} 
