import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FormProperty from '../../organisms/Form/FormProperty';
import { getProperty } from '../../services/actions';
import { Method, Property } from '../../static/types';
import Loading from '../../atoms/Loading';
import NoContent from '../../atoms/NoContent';

const EditProperty = (): ReactElement => {
  const { search } = useLocation();

  const [property, setProperty] = useState<Property  | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const query = new URLSearchParams(search);

  useEffect(() => {
    const id = query.get("id");

    if (!id || property) return;
    
    setLoading(true);
    getProperty(id)
      .then(property => setProperty(property))
      .finally(() => setLoading(false));
  }, [property, query, search]);

  const styles = {
    height: "calc(100vh - 13rem)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  if (loading) {
    return <div style={styles}><Loading /></div>;
  }

  return property
    ? <FormProperty defaultValues={property} method={Method.PATCH} title="Atualizar propriedade" />
    : <NoContent />;
}

export default EditProperty;
 