import { useState } from 'react';
import { toast } from 'react-toast';

import LayoutForm from '../Components/LayoutForm';

import { createClient, updateClient } from '../../../services/actions';
import { Client, Method } from '../../../static/types';
import { data, initialValues } from './form-data';
import { InputSize } from '../../../atoms/Input';

interface FormClient {
  defaultValues?: any;
  method?: Method;
  size?: InputSize;
  customCallback?: (event: any) => void;
}

const FormClient = (props: FormClient) => {
  const {
    defaultValues,
    method = Method.POST,
    size = InputSize.MEDIUM,
    customCallback
  } = props;
  
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: Client) => {
    setLoading(true);

    // @ts-ignore
    let response;

    if (method === Method.POST) {
      response = createClient(values);
    } else if (method === Method.PATCH) {
      response = updateClient({ ...defaultValues, ...values });
    }

    if (!response) return;

    response
      .then(() => {
        // @ts-ignore
        customCallback && customCallback(response);
      })
      .finally(() => {
        setLoading(false);

        if (method === Method.POST) {
          toast.success("Cliente cadastrado com sucesso!");
          document.location.reload();
        } else {
          toast.success("Cliente atualizado com sucesso!");
        }
      });
  }

  return (
    <LayoutForm
      data={data}
      handleSubmit={handleSubmit}
      initialValues={defaultValues ?? initialValues}
      loading={loading}
      method={method}
      title="Adicionar cliente"
      size={size}
      submitText={method === Method.PATCH ? 'Atualizar' : 'Cadastrar'}
    />
  );
};

export default FormClient;
